import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import {  LangService } from '../../core/lang.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { Subscription } from 'rxjs';
import { LoginGuardService } from '../../api/login-guard.service';
import { ISectionDef, ITestDef, ISectionMeta } from '../sample-questions/data/sections';
import { reject } from 'q';
import { FormControl } from '@angular/forms';
import { AuthService, getFrontendDomain } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { TextToSpeechService } from '../text-to-speech.service';
import { IQuestionConfig, IQuestionRun } from '../../ui-item-maker/item-set-editor/models';
import { ElementType,IContentElementIframe } from '../models';
import {ChatService} from '../../chat/chat.service';
import { HttpClient } from '@angular/common/http';
import { IMenuTabConfig } from '../../ui-partial/menu-bar/menu-bar.component';
import { TestFormConstructionMethod } from 'src/app/ui-item-maker/item-set-editor/models/assessment-framework';
import { DrawDisplayMode } from '../element-render-drawing/constants';
import { IDur, IPanelModuleDef, renderDur } from '../../ui-testtaker/view-tt-test-runner/view-tt-test-runner.component';
import { randArrEntry } from '../../ui-testadmin/demo-data.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { UrlLoaderService } from '../url-loader.service';
import { DevtoolsDetectService } from 'src/app/core/devtools-detect.service';
import { SEBService } from 'src/app/ui-testtaker/seb.service';
import { downloadStr } from 'src/app/core/download-string';

interface ITestState {
  languageCode: string;
  currentSectionIndex: number;
  currentQuestionIndex: number;
  questionStates: any;
  isSubmitted?: boolean;
}
interface ISectionState{
  numUnfilledResponses?: number;
  timeSpent?:number;
}

enum EXCLUDE_ITEM_OPEN_LOG_REASONS {
  NEW_SECT_PREAMBLE = 'NEW_SECT_PREAMBLE',
  NG_DESTROY = "NG_DESTROY"
}

const concatObjects = (...sources) => {
  const target = {};
  sources.forEach(el => {
     Object.keys(el).forEach(key => {
        target[key] = el[key];
     });
  });
  return target;
}

enum STUDENT_ACTIVITY_SLUGS {
  STUDENT_TEST_ITEM_OPEN = "STUDENT_TEST_ITEM_OPEN",
  STUDENT_TEST_ITEM_NAVIGATE_AWAY = "STUDENT_TEST_ITEM_NAVIGATE_AWAY",
  STUDENT_TEST_ITEM_KEEP_ALIVE = "STUDENT_TEST_ITEM_KEEP_ALIVE",
  STUDENT_SECTION_INFO_OPEN = "STUDENT_SECTION_INFO_OPEN",
  STUDENT_SECTION_INFO_CLOSE = "STUDENT_SECTION_INFO_CLOSE",
}

const ITEM_RESAVE_INTERVAL = 60*1000; //every minute, trigger a save

@Component({
  selector: 'test-runner',
  templateUrl: './test-runner.component.html',
  styleUrls: ['./test-runner.component.scss']
})
export class TestRunnerComponent implements OnInit,AfterViewInit, OnDestroy {

  @ViewChild('questionDisplay', { static: false }) questionDisplay: ElementRef<HTMLDivElement>;
  @ViewChild('topBar', { static: false }) topBar: ElementRef<HTMLDivElement>;
  
  @Input() testTakerName: string;
  @Input() confirmReportData: Object;
  @Input() testFormType: string;
  @Input() testFormId: number;
  @Input() currentTestDesign: ITestDef;
  @Input() questionSrcDb: Map<number, IQuestionRun>;
  @Input() testLang: string;
  @Input() testSessionId: number;
  @Input() attemptKey: string;
  @Input() sectionIndexInit: number;
  @Input() questionIndexInit: number;
  @Input() isIssueReportingEnabled: boolean;
  @Input() questionStates: {[key: string]: any};
  @Input() dateTimeStart: moment.Moment;
  @Input() dateString:string;
  @Input() startedOn:string;
  @Input() regularTimeRemaining: IDur;
  @Input() isPrintMode: boolean;
  @Input() isNavFooterDisabled: boolean;
  @Input() isChatEnabled: boolean;
  @Input() isTimeEnabled: boolean = true;
  @Input() isHelpEnabled: boolean = true;
  @Input() autoScrollOnSelect: boolean = false;
  @Input() isText2SpeechEnabled: boolean = true;
  @Input() isShowQuestionLabel: boolean;
  @Input() documentItems: {itemId:number, caption:string}[];
  @Input() helpPageItem: number;
  @Input() isExitEnabled: boolean;
  @Input() checkTime: () => Promise<any>;
  @Input() checkChat: () => Promise<any>;
  @Input() instit_group_id: number;
  @Input() institution: string;
  @Input() format: string;
  @Input() timeSpentSinceStart:string;
  @Input() disableDrawingTools:boolean;
  @Input() attemptHash: string;

  @Input() saveQuestion: (data: any) => Promise<any>;
  @Input() submitTest: () => Promise<any>;
  @Input() goToDashboard: () => any;
  @Output() exit = new EventEmitter();

  dayStarted: number;
  yearStarted: number;
  isShowFormulaSheet = false;
  isShowDocuments = false;
  showEraser = false;
  showLine = false;
  showHighlight = false;
  transValue 
  drawMode = '';
  currIEZoom = 100
  isShowOverlay = false;
  showDeactivatedMode = false;
  isZoomIn = false;
  isZoomOut = false;
  overlayState ={
    "off":true,
    "on":false,
    "deactivate":false
  }
  iframeUrl 
  element:IContentElementIframe = {
     elementType:ElementType.IFRAME,
     url:''
  }
  lineReaderPosition: {x: number, y: number} = {x: 0, y: 0};
  monthSlugs = {0:'lbl_reportDate_jan', 1:'lbl_reportDate_feb', 2:'lbl_reportDate_mar', 
  3:'lbl_reportDate_apr', 4:'lbl_reportDate_may', 5:'lbl_reportDate_jun', 
  6:'lbl_reportDate_july', 7:'lbl_reportDate_aug', 8:'lbl_reportDate_sep',
  9:'lbl_reportDate_oct', 10:'lbl_reportDate_nov', 11:'lbl_reportDate_dec'};
  previousSessionMeta: { [key: string]: ISectionState };
  currSection: string;
  isSubmitting: boolean =false;
  report: any;
  reportError: boolean;
  reportErrorMsg: any;

  private devtoolsSub: Subscription;
  isDebugMode:boolean;
  lastResponseDebug:{label:string, test_question_id:number, formatted_response:string, score:number, weight:number}
  previousLoggedItemOpenId: number;
  currentTestQuestionStartTime: number; // epoch

  
  constructor(
    public lang: LangService,
    private loginGuard: LoginGuardService,
    private location: Location,
    private router: Router,
    private routes: RoutesService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private textToSpeech: TextToSpeechService,
    private httpClient: HttpClient,
    public chatService: ChatService,
    private whitelabel: WhitelabelService,
    private safeUrl: UrlLoaderService,
    private seb: SEBService,
    private devtoolsDetect: DevtoolsDetectService,
  ) { }

  zoomLevel = 1;
  minZoomLevel = 0.6;
  maxZoomLevel = 2;
  zoomIncrement = 0.2;

  testState: ITestState;

  routeSub: Subscription;

  isSyncing: boolean;

  isShowingSectionInfo: boolean;
  isFormulasToggledOn: boolean;
  isCalcToggledOn: boolean;
  isHighContrast: boolean;
  isHelpOverlay: boolean;
  sectionTime: number;
  sectionTimeRemaining;
  helpScreenLayout:any;
  isShowingConfirmationReport:boolean; 
  ticker;
  questionResaveInterval;
  isPaused: boolean = false; 

  isSavingResponse: boolean;
  currentModal: any;
  isShowingTime;
  isShowingChat:boolean = false;
  isShowingReport;
  issueReportMessage = new FormControl();
  isLineReaderActive: boolean;
  isTestNavExpanded: boolean;
  isToolbarExpanded: boolean;
 
  documentViews: IMenuTabConfig<number>[];
  selectedDocumentId:number;
  numUnfilledQbySection:number;
  sectionTimeSpent = [];
  sessionMeta: { [key: string]: ISectionState };

  isLogsVisible = false;
  logQueue:any[] = []

  renderDur = renderDur;

  ngOnInit() {
    if (!this.isPrintMode){
      window.scrollTo(0, 0);
    }
    this.isDebugMode = !! this.devtoolsDetect.debugModePass

    this.initTestDef();
    this.initTestState();

    if (this.isChatEnabled){
      this.initChatPage();
    }
    if (this.isHelpEnabled){
      this.initHelpPage();
    }

    this.documentViews = [];
    if (this.documentItems){
      Promise.all(
        this.documentItems.map(document => {
          this.documentViews.push({
            id: document.itemId, 
            caption: document.caption, 
          })
          return this.loadDocument(document.itemId);
        })
      )
      .then(()=>{
        this.selectDocumentView(this.documentItems[0].itemId)
      });
    }

    // this.routeSub = this.route.params.subscribe(e => this.getRouteParams(e));
    this.lastFillState = this.isQuestionFilled( this.getCurrentQuestionIndex() );
    this.getIframeURL();  
    this.currSection = this.getCurrentSectionIndex().toString()

    if (this.confirmReportData){
      this.previousSessionMeta = JSON.parse(this.confirmReportData?.["prevSessionMeta"]);
    }
    // console.log(this.previousSessionMeta)
    if( this.previousSessionMeta ){
      Object.keys(this.previousSessionMeta).forEach(key =>{
        this.sectionTimeSpent[key] = this.previousSessionMeta[key].timeSpent;
      })
    }
    
    if(this.confirmReportData) {
      this.dayStarted = this.confirmReportData?.["dateTimeStart"]._d.getDate();
      this.yearStarted = this.confirmReportData?.["dateTimeStart"]._d.getFullYear();
    }
  }

  numDebugAggTrigger = 0;
  async triggerDebugModeAgg(){
    this.numDebugAggTrigger ++;
    if (this.numDebugAggTrigger >= 10){
      this.numDebugAggTrigger = 0;
      this.triggerDebugMode();
    }
  }
  async triggerDebugMode(){
    const password  = prompt('Enter DEBUG');
    if (password){
      try {
        const {isValid} = await this.auth.apiCreate('public/student/debug', {password})
        if (isValid){
          this.isDebugMode = true;
          this.devtoolsDetect.debugModePass = password;
        }
      }
      catch(e){
        alert('Please contact technical support.')
      }
    }
    this.numDebugAggTrigger = 0;
  }


  documentMap:Map<number, IQuestionConfig> = new Map();
  activeDocument
  loadDocument(itemId:number){
    return new Promise((resolve, reject) => {
      const item = <any>this.questionSrcDb.get(itemId);
      this.documentMap.set(+itemId, item);
      resolve(item);
    })
  }
  
  selectDocumentView(itemId:number){
    this.selectedDocumentId = itemId
    this.activeDocument = this.documentMap.get(itemId);
  }

  ngAfterViewInit(){
  }

  lastFillState:boolean;
  checkAutoScroll(){
    const currentFillState = this.isQuestionFilled( this.getCurrentQuestionIndex() );
    if (currentFillState !== this.lastFillState){
      if (this.autoScrollOnSelect && currentFillState){
        const el = document.getElementById('btn-submit-question');
        if (el){
          el.scrollIntoView({behavior: 'smooth', block: 'end'});
        }
      }
      this.lastFillState = currentFillState;
    }
  }

  getIframeURL(){
    let url = window.location.protocol + "//" + window.location.host + "/assets/sci_calc/index.html"
    this.element.url = url;
  
  }
  setEraser(){
    this.showLine = false;
    this.showHighlight = false;
    if(this.isShowOverlay && !this.showDeactivatedMode){
      this.showEraser = true;
    }
    
  }
  setHighlighter(){
    this.showEraser = false;
    this.showLine = false;
    this.toggleZwibbler()
    if (!this.overlayState.deactivate && this.showHighlight){
      this.disableOverlay()
    }else{
      this.showHighlight = true;
    }
   
  }
  setLine(){
    this.showHighlight = false;
    this.showEraser = false;
    this.toggleZwibbler();
    if (!this.overlayState.deactivate && this.showLine){
      this.disableOverlay()
    }else{
      this.showLine = true;
    }
    

  }
  toggleZwibbler(){
   if (this.overlayState.off){
    this.overlayState.off = false;
     this.overlayState.on = true;
     this.isShowOverlay = true;
     return;
   }
   if (!this.overlayState.deactivate && this.showEraser){
      this.showEraser = false;
     return
   }
 

   if (this.overlayState.deactivate){
     this.overlayState.deactivate = false;
     this.showDeactivatedMode = false;
   }

  }
  disableOverlay(){
    this.showEraser = false;
    this.showHighlight = false;
    this.showLine = false;
    if (!this.overlayState.deactivate){
         this.overlayState.deactivate = true;
         this.showDeactivatedMode = true;
       }
  }

  getDrawingDisplayMode(){
    return DrawDisplayMode.TEST_RUNNER;
  }

  
  sectionTimer(sectionIndex: number){
      let sumOfPreviousSections = 0;
      for(let i=0; i<sectionIndex; i++){
        sumOfPreviousSections += this.sectionTimeSpent[i];
      }
      this.sectionTime = this.confirmReportData?.["timeSpent"] - sumOfPreviousSections;
      this.sectionTimeSpent[sectionIndex] = Math.round(this.sectionTime);
      this.sessionMeta = { [this.currSection] : concatObjects(this.sessionMeta[this.currSection], {timeSpent : this.sectionTimeSpent[sectionIndex]}) }

  }
    
    
    /* 
    this is not correct... it should be when they start a section and it should be persisted to the db
    this.ticker = setInterval(() => {
      const section = this.getCurrentSection();
      // console.log('section', section)
      this.checkAutoScroll(); // this has nothing to do with the timer countdown disp[lay]
      if (section && section.isTimeLimit) {
        let secondsRemaining = this.getCurrentSection().timeLimitMinutes * 60;
        let secondsSpent = ((new Date()).valueOf() - this.sectionTimeStarted) / 1000;
        secondsRemaining -= secondsSpent;
        let secondsDisplay = Math.round(secondsRemaining % 60);
        // console.log('getSectionTimeRemaining', secondsSpent)
        let minutesDisplay = Math.round((secondsRemaining - secondsDisplay) / 60);
        this.sectionTimeRemaining = this.leadingZero(minutesDisplay) + ':' + this.leadingZero(secondsDisplay);
      }
    }, 100); 
    */
  

  initChatPage(){
    this.chatService.isSupervisor = false;
    this.chatService.isInvigilator = false;
    this.chatService.isTestTaker = true;
    this.chatService.uid = this.auth.user().value.uid;
    this.chatService.markingPoolId = this.testSessionId;
    // this.chatService.selectedMarker = this.markerId;
    this.chatService.group_id = this.instit_group_id;
    this.chatService.initSocket();
    const processSocketReconnection = () => this.chatService.disconnect();
    // setInterval(processSocketReconnection, 3000000);
  }

  initHelpPage(){
    if (this.helpPageItem){
      this.helpScreenLayout = this.questionSrcDb.get(+this.helpPageItem);
    }
    else{
      return this.auth
        .apiGet(this.routes.TEST_TAKER_DATA_DOWNLOAD, 0)
        .then(helpScreenLayout =>{
          if (this.isLang('en')){
            this.helpScreenLayout = <any> helpScreenLayout;
          }
          else {
            this.helpScreenLayout = <any> {
              ... helpScreenLayout,
              ... helpScreenLayout.langLink,
            };
          }
        })
    }
  }

  ngOnDestroy() {
    this.clearQuestionResaveInterval();
    clearInterval(this.ticker);
    this.logQuestionActivity(EXCLUDE_ITEM_OPEN_LOG_REASONS.NG_DESTROY);
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }
  toggleTextToSpeech() {
    this.textToSpeech.toggle();
  }
  isTextToSpeechActive() {
    return this.textToSpeech.isActive;
  }
  toggleHelpScreen() {
    //console.log(this.helpScreenLayout)
    if(this.isHelpOverlay){
      document.getElementById("quest-cont").style.display = "flex"
      this.isHelpOverlay = false;
    }
    else{
      document.getElementById("quest-cont").style.display = "none"
      this.isHelpOverlay = true;
    }
  }
  getHelpScreenLayout(){
    return this.helpScreenLayout;
  }

  saveCounter = 0;
  private _saveQuestion() {
    if (this.isSavingResponse) {
      reject();
    }
    this.isSavingResponse = true;
    return new Promise<void>((resolve, reject) => {
      const content = this.getActiveQuestionContent();
      const test_question_version_id = content ? content.test_question_version_id : 0;
      const saveQuestionAndHandleErrors = async () => {
        this.isSavingResponse = true;
        const test_question_id = this.getActiveQuestionId();
        const response_raw = JSON.stringify(this.getActiveQuestionState());
        if (this.isDebugMode) {
          try {
            const {
              formatted_response,
              score,
              weight
            } = await this.auth.apiPatch('public/student/debug', test_question_id, { response_raw, password: this.devtoolsDetect.debugModePass })

            this.lastResponseDebug = {
              label: this.getQuestionLabel(test_question_id),
              test_question_id: test_question_id,
              score,
              weight,
              formatted_response
            }
          }
          catch (e) {
            this.lastResponseDebug = null;
            console.error('Error capturing last response')
          }
        }
        this.saveQuestion({
          test_question_id: this.getActiveQuestionId(),
          test_question_version_id,
          question_index: this.getCurrentQuestionIndex(),
          section_index: this.getCurrentSectionIndex(),
          response_raw: JSON.stringify(this.getActiveQuestionState()),
          response: this.getActiveQuestionResponse(),
        })
          .then(() => {
            this.isSavingResponse = false;
            if(this.isPaused){
              this.isPaused = false;
              this.loginGuard.confirmationReqActivate({
                caption: "mpt_msg_attempt_resumed",
                hideCancel: true,
              });
            }
            resolve();
          })
          .catch(e => {
            console.log(e)
            this.isSavingResponse = false;
            const questionSaveErr = this.parseQuestionSaveError(e.message);

            if (!questionSaveErr) {
              this.loginGuard.confirmationReqActivate({
                caption: 'msg_save_question_err',
                hideCancel: true,
                btnProceedCaption: this.lang.tra('btn_retry'),
                confirm: () => {
                  saveQuestionAndHandleErrors();
                }
              })
            } else {
              let config: any = {};
              if (e.message === 'ERR_NEW_CONNECTION') {
                // force logout
                config = {
                  hideCancel: true,
                  btnProceedCaption: this.lang.tra('btn_ok'),
                  confirm: () => {
                    return this.auth.forceLogout(this.lang.getCurrentLanguage(), this.auth.myAccountType());
                  }
                }
              }
              if(questionSaveErr.dismiss){
                config = {
                  hideCancel: true,
                  btnProceedCaption: this.lang.tra('btn_dismiss'),  
                }
              }
              if(!questionSaveErr.skip){
                this.loginGuard.confirmationReqActivate({
                  caption: this.lang.tra(questionSaveErr.slug),
                  ...config
                });
              }
              reject(e);
            }
          });
        }
        saveQuestionAndHandleErrors();
      });
  }

  getLogo(){
    let url;
    if (this.lang.c() === 'en'){
      url = this.whitelabel.getSiteText('asmt_logo_en')
    }
    else if (this.lang.c() === 'fr'){
      url = this.whitelabel.getSiteText('asmt_logo_fr')
    }
    return url;
  }

  getLogoSafe(){
    return this.safeUrl.sanitize(this.getLogo());
  }

  parseQuestionSaveError(msg: string) {
    let dismiss = false;
    let skip = false;
    let slug = "";
    switch (msg) {
      case 'TIME_OUT': 
        slug = 'msg_max_time';
        break;
      case 'NOT_BOOKED_APPL': 
        slug = 'msg_no_longer_booked';
        break;
      case 'ATTEMPT_CLOSED': 
        slug = 'msg_already_submitted';
        break;
      case 'SESSION_CLOSED': 
        slug = 'msg_ses_clsd';
        break;
      case 'SESSION_ENDED': 
        slug = 'msg_already_ended';
        break;
      case 'MARKED_NO_ID': 
        slug = 'msg_photo_id_error';
        break;
      case 'MARKED_ABSENT': 
        slug = 'msg_absent';
        break;
      case 'NOT_VERIFIED': 
        slug = 'msg_identity_error';
        break;
      case 'ATTEMPT_PAUSED':
        // No need to ping the applicant that they've been paused on every minute that passes while they are paused. 
        //   They only need to see it once.
        if(this.isPaused){
          skip = true;
        }else{
          this.isPaused = true;
          dismiss = true;
          slug = 'mpt_msg_attempt_paused';
        }
        break;
      case 'SESSION_PAUSED': 
        slug = 'msg_session_paused';
      case 'ERR_NEW_CONNECTION': 
        slug = 'msg_err_new_connection';
    }
    return {slug, dismiss, skip};
  }

  getActiveQuestionResponse() {
    const state = this.getActiveQuestionState();
    const responses = [];
    Object.keys(state).forEach(entryId => {
      const eRes = state[entryId];
      if (eRes.selections && eRes.selections[0]) {
        const entryResponses = eRes.selections.map(s => s.i);
        responses.push(entryResponses.join(','));
      }
    });
    return responses.join(';');
  }

  // getRouteParams(routeParams: any) {
  //   this.initTestDef();
  //   this.initTestState();
  // }

  initTestDef() {
    // compute section meta (mostly for the progress bar)
    let qsPrec = 0;
    this.currentTestDesign.sections.forEach(section => {
      const qs = section.questions.length;
      let meta: ISectionMeta = { qs, qsPrec, };
      section.__meta = meta;
      qsPrec += qs;
    });
    const qsTotal = qsPrec;
    // store the total number of questions
    this.currentTestDesign.__meta = {qs: qsTotal};
    // compute the position of the marker on the progress bar
    this.currentTestDesign.sections.forEach(section => {
      const m = section.__meta;
      const qIG = m.qsPrec + m.qs;
      const proportion = qIG / qsTotal;
      const markLoc = this.renderLocProp(proportion);
      section.__meta.markLoc = markLoc;
    });
  }

  private renderLocProp(p: number, asNum: boolean= false) {
    return Math.round(100 * p) + (asNum ? '' : '%');
  }

  showSectionInfo() {
    if (this.getCurrentSectionPreambleContent()) {
      this.isShowingSectionInfo = true;
    }
  }
  hideSectionInfo() {
    this.isShowingSectionInfo = false;
    this.logQuestionActivity(); // if first question is preamble then on hide we need to log navigation to first question
  }

  getCurrentProgressLoc(asNum: boolean= false) {
    const section = this.getCurrentSection();
    const qIR = this.getCurrentQuestionIndex() + 1;
    const qsTotal = this.currentTestDesign.__meta.qs;
    const qIG = section.__meta.qsPrec + qIR;
    const proportion = qsTotal === 0 ? 1 : qIG / qsTotal;
    return this.renderLocProp(proportion, asNum);
  }

  initTestState() {
    this.testState = {
      languageCode: this.testLang,
      currentSectionIndex: this.sectionIndexInit,
      currentQuestionIndex: this.questionIndexInit,
      questionStates: this.questionStates,
    };
    if(this.testState.currentQuestionIndex > this.getCurrentQuestions().length-1 || this.testState.currentQuestionIndex < 0 ){
         this.testState.currentQuestionIndex = 0;}
    if (this.testState.currentQuestionIndex === 0) {
      if (this.getCurrentSectionPreambleContent()) {
        this.isShowingSectionInfo = true;
      } else {
        this.logQuestionActivity()
      }
    }
  }

  activateModal(caption: string, confirm: any, btnProceedCaption?:string, btnCancelCaption?:string) {
    this.loginGuard.confirmationReqActivate({ 
      caption, 
      confirm,
      btnProceedCaption,
      btnCancelCaption,
    });
  }

  getDate(){
    let monthIndex = this.confirmReportData?.["dateTimeStart"]._d.getMonth();
    return this.monthSlugs[monthIndex];
  }

  getStartedAt(){
    return this.convertTimeString(this.confirmReportData?.["dateTimeStart"]._d);
  }
  
  getTimeSpentSinceStart(){
      let timeSpent = 0;
      for(let el in this.sectionTimeSpent){
        if(this.sectionTimeSpent.hasOwnProperty(el)){
          timeSpent+= parseFloat(this.sectionTimeSpent[el])
        }
      } 
      return this.timeConvert(timeSpent)     
  }

 public convertTimeString = (date:Date) => {
    let timeString = '';
    if(this.lang.c() == 'en'){
      if(date.getUTCHours() < 12){
        timeString = date.getUTCHours()+ ":"
                      + (date.getMinutes()<10 ?'0':'')
                      +date.getMinutes()+ " AM";

      }else if(date.getUTCHours() === 12){
        timeString = date.getUTCHours()+ ":" 
                      + (date.getMinutes()<10 ?'0':'')
                      +date.getMinutes()+ " PM";
      } else{
        timeString = date.getUTCHours() - 12 + ":" 
                      + (date.getMinutes()<10 ?'0':'')
                      +date.getMinutes()+ " PM";
      }
    } else {
        timeString = (date.getUTCHours()<10 ?'0':'') +date.getUTCHours()+ "h"
                          + (date.getMinutes()<10 ?'0':'')+date.getMinutes();
    }
                  
    return timeString;
  } 

  public timeConvert= (num:number) => { 
    let hours = Math.floor(num / 60);  
    let minutes = Math.floor(num % 60);
    return hours + " "+this.lang.tra('txt_hours') +" "+ minutes + " minutes";         
  }

  isFormulasAvailable() {
    return this.getCurrentSection().hasFormulas;
  }
  isCalcAvailable() {
    return this.getCurrentSection().hasCalculator;
  }

  getCurrSectionTimeLimit(){
    const currentSection  = this.getCurrentSection();
    if(!currentSection?.isTimeLimit){
      return false;
    }
    return currentSection.isTimeLimit
  }

  getCurrentSectionIndex(): number {
    if (this.testState) {
      return this.testState.currentSectionIndex;
    }
    return -1;
  }
  getCurrentQuestionIndex(): number {
    if (this.testState) {
      return this.testState.currentQuestionIndex;
    }
    return -1;
  }
  getCurrentQuestionStates(): any {
    if (this.testState) {
      return this.testState.questionStates;
    }
    return {};
  }
  getCurrentSection(): ISectionDef {
    return this.getSection(this.getCurrentSectionIndex());
  }

  leadingZero(num) {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  }
  getSectionTimeRemaining() {

  }

  getSection(i: number) {
    return this.currentTestDesign?.sections?.[i] || <any> {questions: []};
  }

  getCurrentQuestions(): number[] {
    return (this.getCurrentSection()).questions || [];
  }

  getSectionQuestions(sectionIndex: number){
    return (this.getSection(sectionIndex)).questions;
  }

  getActiveQuestionId() {
    const qId = this.getCurrentQuestions()[this.getCurrentQuestionIndex()];
    // if (!qId) {
      // console.warn('Null question');
    // }
    return qId;
  }

  getSectionPreambleContent(section: ISectionDef) {
    if(!section?.preamble){
      return undefined;
    }
    const content = this.getQuestionDef(section.preamble);
    return content;
  }
  getCurrentSectionPreambleContent() {
    // replaced getSectionInfoContent
    const section = this.getCurrentSection();
    const preambleContent = this.getSectionPreambleContent(section);
    return preambleContent;
  }

  getActiveQuestionContent() {
    const content = this.getQuestionDef(this.getActiveQuestionId());
    // console.log('content', content)
    return content;
  }

  getCurrentQuestionId(){
    const qIndex = this.getCurrentQuestionIndex()
    return this.getCurrentQuestions()[qIndex];
  }

  isCurrentQuestionFilled() {
    return this.isQuestionFilled(this.getCurrentQuestionIndex());
  }

  isQuestionFilled(qIndex: number) {
    const states = this.getCurrentQuestionStates();
    const qId = (this.getCurrentQuestions() || [])[qIndex];
    if (!qId) {
      return false;
    }
    let qState = states[qId];
    if (qState) {
      try {
        let isAllFilled = true;
        let isGlobalFilled = false;
        let isGlobalFilledOverride = (+qId === 9); // temporary
        let isAtLeastOneEntry = false;
        _.each(qState, entry => {
          isAtLeastOneEntry = true;
          if (!entry.isFilled) {
            isAllFilled = false;
          } 
          else {
            if (entry.isCorrect && (entry.isGlobalFill || isGlobalFilledOverride)) {
              isGlobalFilled = true;
            }
          }
        });
        console.log(qId, isAllFilled, isGlobalFilled, !isAtLeastOneEntry)
        return isAllFilled || isGlobalFilled || !isAtLeastOneEntry;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  containsWideLoad() {
    const question = this.getActiveQuestionContent();
    let isMatch = false;
    if (question) {
      question.content.forEach( element => {
        if (element.elementType === ElementType.SBS) {
          isMatch = true;
        }
      });
    }
    return isMatch;
  }

  getActiveQuestionState() {
    const states = this.getCurrentQuestionStates();
    const qId = this.getActiveQuestionId();
    let qState = states[qId];
    if (!qState) {
      qState = states[qId] = {};
    }
    return qState;
  }

  getQuestionDef(questionId: number) {
    return <IQuestionConfig> this.questionSrcDb.get(questionId);
  }
  getQuestionLabel(questionId: number) {
    const question = this.getQuestionDef(questionId);
    console.log('test')
    if (question){
      return question.label;
    }
  }

  sectionPrintDisplayRef = new Map();
  toggleSectionPrint(id:number){
    this.sectionPrintDisplayRef.set(id, !this.sectionPrintDisplayRef.get(id))
  }

  showCurrentQuestionLabel(){
    alert("Item Label for Lookup: " + this.getQuestionLabel(this.getActiveQuestionId()))
  }

  scrollQuestionIntoView() {
    if (!this.isPrintMode){
      const el = this.questionDisplay.nativeElement;
      el.scrollIntoView();
    }
  }

  selectSectionAndQuestion(sectionIndex, questionIndex) {
    this._saveQuestion().then(() => {
      if (sectionIndex !== this.testState.currentSectionIndex) {
        //this.sectionTimeStarted = (new Date()).valueOf();
      }
      this.testState.currentSectionIndex = sectionIndex;
      this.testState.currentQuestionIndex = questionIndex;
      this.scrollQuestionIntoView();
    });
  }

  selectQuestion(questionIndex) {
    // Restart the intreval on every question selection
    this.restartQuestionResaveInterval();
    // Reset the isPaused status so that if the applicant is paused and they  
    //   navigate between questions, they will get the "you've been paused" popup 
    //   as expected and start the isPaused logic again.
    this.isPaused = false;
    if(this.testState.currentQuestionIndex > this.getCurrentQuestions().length-1 || this.testState.currentQuestionIndex < 0 ){
       this.testState.currentQuestionIndex = questionIndex;
    }
    this.isShowingSectionInfo = false;
    this.isHelpOverlay = false;
    document.getElementById("quest-cont").style.display = "flex"
    if(!this.showDeactivatedMode && this.isShowOverlay){
      this.disableOverlay()
    }
    this._saveQuestion().then(() => {
      this.testState.currentQuestionIndex = questionIndex;
      this.clearTools();
      this.lastFillState = this.isQuestionFilled(questionIndex);
      this.logQuestionActivity();
      if (!this.isPrintMode){
        window.scrollTo(0, 0);
      }
    });
  }

  clearTools(){
    this.isFormulasToggledOn = false;
    this.isCalcToggledOn = false;
  }

  gotoNextQuestion() {
    if(this.testState.currentQuestionIndex > this.getCurrentQuestions().length-1 || this.testState.currentQuestionIndex < 0 ){
      this.testState.currentQuestionIndex = 0;
      this.selectQuestion(this.testState.currentQuestionIndex)
    } else{ this.selectQuestion(this.testState.currentQuestionIndex + 1); }
  }

  isOnLastQuestion() {
    return this.testState.currentQuestionIndex >= this.getCurrentQuestions().length - 1;
  }

  countNumCurrentQuestionsUnfilled() {
    let numUnfilled = 0;
    let questionIds = this.getCurrentQuestions();
    if (!questionIds) {
      console.error('no questions');
      return 0;
    }
    questionIds.forEach((qId, qIndex) => {
      if (!this.isQuestionFilled(qIndex)) {
        numUnfilled ++;
      }
    });
    return numUnfilled;
  }

  numUnfilledQuestInSection(sectionIndex:number, numUnFilled:number){
    this.numUnfilledQbySection= numUnFilled;
  }

  reviewAndSubmit() {
    this.currSection = this.testState.currentSectionIndex.toString()
    this.overlayState.deactivate = true;
    this.showDeactivatedMode = true;
    const numUnfilled = this.countNumCurrentQuestionsUnfilled();
    let preMessage = '';
    if (numUnfilled > 0) {
      preMessage += this.lang.tra('mpt_alert_unfilled_questions', null, {questionNum: numUnfilled}) + ' ';
    }
    this.numUnfilledQuestInSection(this.testState.currentSectionIndex, numUnfilled);

    if(this.previousSessionMeta){
      console.log(this.currSection)
      if(!(this.currSection in this.previousSessionMeta)){
        this.sessionMeta = { [this.currSection] : {numUnfilledResponses : this.numUnfilledQbySection}}
      }
      else{
        this.previousSessionMeta[this.currSection].numUnfilledResponses = this.numUnfilledQbySection;
        this.sessionMeta = this.previousSessionMeta;
      }
    } else{
      console.log(this.currSection)
      this.sessionMeta = { [this.currSection] : {numUnfilledResponses : this.numUnfilledQbySection}}
    }
    // Restart the intreval on every section submission and test's final submission
    this.restartQuestionResaveInterval();
    // Reset the isPaused state so that if the applicant is paused they will be notified of
    //  their paused status and if a minute passes since their click on this function, 
    //  they will not be auto re-notified of the same paused status, unless they trigger this again.
    this.isPaused = false;
    // check if the test will be done with this
    if (this.testState.currentSectionIndex >= this.currentTestDesign.sections.length - 1) {
      this.confirmAndSubmitTest(preMessage);
    } 
    else {
      this.confirmAndSubmitSection(preMessage)
    }
  }

  private confirmAndSubmitSection(preMessage:string){
    this.clearTools();
    let isConfirmed:boolean;
    this.activateModal(preMessage + this.lang.tra(this.getAlertKKSubmitSectionSlug()), () => {
      if (isConfirmed){return;}
      isConfirmed = true;
      this._saveQuestion().then(() => {
        this.gotoNextSection();
      });
    }, this.lang.tra('alert_KK_SUBMIT_TEST_yes'), this.lang.tra('alert_KK_SUBMIT_TEST_no_simple') );
  }

  private gotoNextSection(){
    this.isSubmitting = true
    const test_session_id = this.testSessionId
    this.currSection = this.testState.currentSectionIndex.toString()
    console.log(this.currSection)
    const sebFailSafe: number = this.seb.getSEBFaileSafeCheck();
    const validatedSEBConfig:number = this.auth.getSEBValidatorStatus();
    if(this.testState.currentSectionIndex === 0){
      this.sectionTime = this.confirmReportData?.["timeSpent"];
      this.sectionTimeSpent[0] = this.sectionTime ? Math.round(this.sectionTime) : 0;
      this.sessionMeta = { [this.currSection] : concatObjects(this.sessionMeta[this.currSection], {timeSpent : this.sectionTimeSpent[this.testState.currentSectionIndex]}) }
      this.auth.apiPatch(this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT,this.confirmReportData?.["attemptId"], this.sessionMeta, { query: {test_session_id, sebFailSafe, validatedSEBConfig, domain: getFrontendDomain()} }).then(res=>{
        this.previousSessionMeta = this.sessionMeta;
        console.log(this.previousSessionMeta)
      })
    } else {
      this.sectionTimer(this.testState.currentSectionIndex)
      if(this.previousSessionMeta){this.sessionMeta = concatObjects(this.previousSessionMeta,this.sessionMeta)} 
      this.auth.apiPatch(this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT,this.confirmReportData?.["attemptId"], this.sessionMeta, { query: {test_session_id, sebFailSafe, validatedSEBConfig, domain: getFrontendDomain()} }).then(res=>{
        this.previousSessionMeta = this.sessionMeta;
        console.log(this.previousSessionMeta)
      })

    }
    this.processRouting().then(()=>{
      this.testState.currentSectionIndex ++;
      this.testState.currentQuestionIndex = 0;
      this._saveQuestion().then(() =>{
        this.isSubmitting = false;
        const isPreambleAvail = !!this.getCurrentSectionPreambleContent();
        if (isPreambleAvail) {
          this.isShowingSectionInfo = true;
          this.logQuestionActivity(EXCLUDE_ITEM_OPEN_LOG_REASONS.NEW_SECT_PREAMBLE);
        } 
        else {
          this.isShowingSectionInfo = false;
          this.logQuestionActivity()
        }
      })
      
    })    
  }

  private processRouting(){
    const currentSectionIndex = this.testState.currentSectionIndex;
    return new Promise<void>((resolve, reject) => {
      if (this.testFormType === TestFormConstructionMethod.MSCAT){
        const currentSection:IPanelModuleDef = this.getSection(currentSectionIndex);
        const currentModuleId = currentSection.moduleId;
        let nextRoute:{module:string, minPropC:number, maxPropC};
        const panelRouting = this.currentTestDesign.panelRouting[''+currentModuleId];
        if (this.currentTestDesign.isPanelRoutingByNumCorrect){
          let propC = this.computePercentageCorrect(currentSectionIndex)
          // console.log('propC', propC)
          panelRouting.forEach(route => {
            if ( (route.minPropC === undefined) || (propC >= route.minPropC) ){
              if ( (route.maxPropC === undefined) || (propC < route.maxPropC) ){
                nextRoute = route;
              }
            }
          })
        }
        if (!nextRoute){
          alert('Number-Correct routing failed or is not enabled for this assessment panel, you will be routed to a random module option.');
          nextRoute = randArrEntry(panelRouting);
        }
        let nextPanelModule;
        this.currentTestDesign.panelModules.forEach(panelModule => {
          if (+panelModule.moduleId === +nextRoute.module){
            nextPanelModule = panelModule;
          }
        })
        console.log('next module', this.currentTestDesign.sections[currentSectionIndex+1]);
        // console.log('next module', nextPanelModule.moduleId);
        this.currentTestDesign.sections[currentSectionIndex+1].questions = nextPanelModule.questions;
        resolve();
      }
      else{
        resolve();
      }
    })
  }

  private computePercentageCorrect(sectionIndex:number) {
    let score = 0;
    let scoreMax = 0;
    const states = this.testState.questionStates;
    this.getCurrentQuestions().forEach(qId => {
      // console.log('states', states[qId]);
      const questionState = states[qId];
      if (questionState){
        let entryScore = 0;
        let entryScoreMax = 0;
        Object.keys(questionState).forEach(entryId => {
          // console.log('entry', questionState[entryId], questionState[entryId].score);
          const entryState = questionState[entryId];
          if (entryState && entryState.score){
            entryScore += +entryState.score;
          }
          entryScoreMax ++;
        });
        if (entryScoreMax > 0){
          score += entryScore / entryScoreMax;
        }
      }
      scoreMax ++;
    });
    if (scoreMax > 0){
      return score / scoreMax;
    }
    return 0;
  }

  private confirmAndSubmitTest(preMessage:string){
    this.currSection = this.testState.currentSectionIndex.toString()
    const test_session_id = this.testSessionId
    this.activateModal(preMessage + this.lang.tra(this.getAlertKKSubmitTestSlug()), () => {
      this.sectionTimer(this.testState.currentSectionIndex);
      if(this.previousSessionMeta){this.sessionMeta = concatObjects(this.previousSessionMeta,this.sessionMeta)}
      this.isSubmitting =true;
      const sebFailSafe: number = this.seb.getSEBFaileSafeCheck();
      const validatedSEBConfig:number = this.auth.getSEBValidatorStatus();
      this.auth.apiPatch(this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT,this.confirmReportData?.["attemptId"], this.sessionMeta, { query: {test_session_id, sebFailSafe, validatedSEBConfig, domain: getFrontendDomain()} }).then(res=>{
        this.previousSessionMeta = this.sessionMeta;
        this.isSubmitting = false;
        console.log(this.previousSessionMeta)
      })
      this._saveQuestion().then(r => {
        this.testState.isSubmitted = true;
        this.isShowingConfirmationReport = true;
        // console.log('submit test')
      });
    }, this.lang.tra('alert_KK_SUBMIT_TEST_yes'), this.lang.tra('alert_KK_SUBMIT_TEST_no_simple') );
  }

  confirmedSubmission(){
    this.loginGuard.confirmationReqActivate({
      caption: this.lang.tra('txt_warn_tt_final_submit'),
      confirm: () => this.submitTest()
    })
  }

  reportIssueThroughInvigilator(){
    this.loginGuard.confirmationReqActivate({
      caption: this.lang.tra('msg_confirm_report'),
      confirm: () => this.openChat()
    })
  }

  zoomIn() {
    if (this.zoomLevel + this.zoomIncrement <=  this.maxZoomLevel) {
      this.zoomLevel += this.zoomIncrement;
    }
  }
  zoomOut() {
    if (this.zoomLevel - this.zoomIncrement >=  this.minZoomLevel) {
      this.zoomLevel -= this.zoomIncrement;
    }
  }

  getDocumentTooltip(){
    if (this.documentItems && this.documentItems.length === 1){
      return this.documentItems[0].caption;
    }
    return this.lang.tra('lbl_documents')
  }

  sendIssueReport() {
    return this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_REPORT_ISSUE,
      {
        test_session_id: this.testSessionId,
        question_id: this.getActiveQuestionId(),
        message: this.issueReportMessage.value,
      }
    )
    .then(e => {
      this.isShowingReport = false;
    });
  }
  reportIssue() {
    this.isShowingReport = true;
  }
  checkTimeLeft() {
    // this.activateModal( this.lang.tra('alert_TIME_LEFT'), ()=>{} );
    if (this.checkTime) {
      this.checkTime();
    }
    this.isShowingTime = true;
  }

  openChat() {
    if (this.checkChat) {
      this.checkChat();
    }
    this.isShowingChat = true;
    this.chatService.newMessage = false;
  }
  
  closeChat() {
    this.isShowingChat = false;
    this.chatService.newMessage = false;
  }

  toggleTestNav() {
    this.isTestNavExpanded = !this.isTestNavExpanded;
  }
  toggleToolbar() {
    this.isToolbarExpanded = !this.isToolbarExpanded;
  }
  
  private lineReaderKeyboardDown = (event: KeyboardEvent) => {
    const baseStepX = 10;
    const baseStepY = 10;
    const fastRatio = 2.5;
    const slowRatio = 0.3;
    const defaultRatio = 1;
    let ratio = defaultRatio;
    let stepX: number;
    let stepY: number;

    if (event.shiftKey && event.ctrlKey) {
      ratio = defaultRatio;
    } else if (event.shiftKey) {
      ratio = fastRatio;
    } else if (event.ctrlKey) {
      ratio = slowRatio;
    }
    stepX = baseStepX * ratio;
    stepY = baseStepY * ratio;
    
    const pos = this.lineReaderPosition;
    switch (event.key) {
      case 'ArrowUp':
        this.lineReaderPosition = { x: pos.x, y: pos.y - stepY }; break;
      case 'ArrowDown':
        this.lineReaderPosition = { x: pos.x, y: pos.y + stepY }; break;
      case 'ArrowLeft':
        this.lineReaderPosition = { x: pos.x - stepX, y: pos.y }; break;
      case 'ArrowRight':
        this.lineReaderPosition = { x: pos.x + stepX, y: pos.y }; break;
    }
  }
  
  toggleLineReader() {
    this.isLineReaderActive = !this.isLineReaderActive;
    if (this.isLineReaderActive) {
      window.addEventListener('keydown', this.lineReaderKeyboardDown);
    } else {
      this.lineReaderPosition = {x:0, y:0};
      window.removeEventListener('keydown', this.lineReaderKeyboardDown);
    }     
  }
  
  toggleHiContrast() {
    this.isHighContrast = !this.isHighContrast;
  }

  toggleDocuments() {
    this.isShowDocuments = !this.isShowDocuments;
  }

  toggleFormula() {
    this.isShowFormulaSheet = !this.isShowFormulaSheet;
  }

  toggleFormulas() {
    this.isFormulasToggledOn = !this.isFormulasToggledOn;
    if (this.isFormulasToggledOn) {
      this.slowScrollToTop();
    }
  }
  
  toggleCalc() {
    this.isCalcToggledOn = !this.isCalcToggledOn;
    // if (this.isCalcToggledOn) {
    //   this.slowScrollToTop();
    // }
  }

  slowScrollToTop() {
    const el = this.questionDisplay.nativeElement;
    el.scrollIntoView({block: 'start'});
    setTimeout(() => {
      const elt = this.topBar.nativeElement;
      elt.scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 100);
  }

  isLang(langCode: string) {
    return (langCode === this.testLang);
  }

  isShowingCalc() {
    return this.isCalcToggledOn;
  }

  getQuestionTitleSlug(){
    return 'title_question';
  }

  getSectionTitleSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'title_stage';
      default: return 'title_section';
    }
  }

  getSectionTimeRemainingSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'tr_stage_time_remaining';
      default: return 'tr_section_time_remaining';
    }
  }

  getAlertUnfilledWarnP2Slug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'alert_UNFILLED_WARN_P2_STAGE_simple';
      default: return 'alert_UNFILLED_WARN_P2_simple';
    }
  }

  getAlertKKSubmitSectionSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'alert_KK_SUBMIT_STAGE';
      default: return 'alert_KK_SUBMIT_SECTION';
    }
  }

  getAlertKKSubmitTestSlug() {
    switch (this.testFormType){
      case TestFormConstructionMethod.MSCAT: return 'alert_KK_SUBMIT_STAGE';
      default: return 'alert_KK_SUBMIT_TEST';
    }
  }

  /**
   * This function sets up an intreval that triggers the _saveQuestion function every minute
   */
  private restartQuestionResaveInterval(){
    this.clearQuestionResaveInterval();
    this.questionResaveInterval = setInterval(() => {
      this._saveQuestion();

      const activeQuesId = this.getActiveQuestionId();
      if(activeQuesId === this.previousLoggedItemOpenId) {
        this.logStudentAction(STUDENT_ACTIVITY_SLUGS.STUDENT_TEST_ITEM_KEEP_ALIVE, {qId: activeQuesId});
      }
    }, ITEM_RESAVE_INTERVAL)
  }

  /**
   * This function clears the ping every minute of question save intreval
   */
  private clearQuestionResaveInterval(){
    if (this.questionResaveInterval){
      clearInterval(this.questionResaveInterval);
    }
  }


  logStudentAction(slug:STUDENT_ACTIVITY_SLUGS, info: any, slugPrefix? : string, isSimulated = false) {
    let prefix = slugPrefix || "";
    prefix += "_";
    const payload = {
      slug: `${prefix}${slug}`,
      data: {
        uid: this.auth.getUid(),
        session_id: this.testSessionId,
        isSimulated,
        localTimestamp: +(new Date()),
        state: {
          section_index: this.testState.currentSectionIndex,
          question_index: this.testState.currentQuestionIndex
        },
        info
      }
    }
    if (this.isLogsVisible){
      payload['_info'] = JSON.stringify(payload.data.info)
      payload['counter'] = this.logQueue.length;
      this.logQueue.unshift(payload)
    }
    if(this.auth.userIsStudent()) {
      this.auth.apiCreate( this.routes.LOG, payload)
    }
  }

  exportLogsJson(){
    for (let record of this.logQueue){
      delete record._info
    }
    downloadStr(JSON.stringify(this.logQueue, null, 2), 'test-runner-process-logs.json')
    for (let record of this.logQueue){
      record._info = JSON.stringify(record.data.info)
    }
  }

  logQuestionActivity(noNextItem?:EXCLUDE_ITEM_OPEN_LOG_REASONS) {
    const activeQuestionId = this.getActiveQuestionId();
    const questionStart = Date.now();
    const questionDuration = questionStart - (this.currentTestQuestionStartTime ?? questionStart);
    const isNewQuestion = activeQuestionId !== this.previousLoggedItemOpenId;
    if (this.previousLoggedItemOpenId && (noNextItem || isNewQuestion)) {
      this.logStudentAction(STUDENT_ACTIVITY_SLUGS.STUDENT_TEST_ITEM_NAVIGATE_AWAY, {
        qId: this.previousLoggedItemOpenId,
        questionDuration,
        noNextItem,
      });
    }

    /* if component is being destroyed there won't be an item to navigate to */
    if (!noNextItem && isNewQuestion) {
      this.logStudentAction(STUDENT_ACTIVITY_SLUGS.STUDENT_TEST_ITEM_OPEN, {
        qId: activeQuestionId,
      });
    }
    if(noNextItem){ // if not logging next item intentionally set previous logged question to null
      this.previousLoggedItemOpenId = null;
    } else {
      this.previousLoggedItemOpenId = activeQuestionId;
    }

    if(isNewQuestion){ // only reset question time if it is a new question
      this.currentTestQuestionStartTime = questionStart;
    }
  }

  logsFilterIn
  logsFilterOut
  private strBitsOverlap(str:string, bitsStr:string){
    const bits = bitsStr.split(',').map(bit => bit.trim())
    for (let bit of bits){
      if (bit && str.includes(bit)){
        return true
      }
    }
    return false
  }
  checkShowLog(eventSlug:string){
    if (this.logsFilterOut && this.strBitsOverlap(eventSlug, this.logsFilterOut)){
      return false
    }
    if (this.logsFilterIn && !this.strBitsOverlap(eventSlug, this.logsFilterIn)){
      return false
    }
    return true;
  }
}
