export interface IContextData {
    id: string,
    homepageRoute: string,
    brandName: string,
    logo: string,
    footer: IFooterDef[][],
    apiAddress: (hostname:string) => string,
    setMPTctrlID?: (hostname:string) => void,
    siteFlags: {[key:string]:boolean},
    siteText: {[key:string]:string},
    hosts: string[],
    defaultTimezone: string,
}

export enum FooterType {
    ROUTE = "ROUTE",
    LINK = "LINK",
    IMAGE_LINKS = "IMAGELINKS"
  }

export type IFooterDef = {
    type?:FooterType,
    route?: string, // internal links
    link?: string, // external links
    caption?: string, // external links
}

export const API_ADDRESS_LOCAL = 'http://localhost:3030';
