import * as moment from 'moment-timezone';

import { Injectable } from '@angular/core';
import { AuthService } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { LoginGuardService } from '../api/login-guard.service';
import { RoutesService } from '../api/routes.service';
import { Router } from '@angular/router';
import { AccountType } from '../constants/account-types';
import { LangService } from '../core/lang.service';
import { TimezoneService } from '../core/timezone.service';
import { EDeliveryOption } from '../api/models/db/test-sessions.schema';
import { INoShowStatus, ITestSessionAvailTT, ITestSessionAvailTTExt } from '../api/models/db/test-session-avail-tt.schema';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';

export interface IMyApplAcct {
  uid: number,
  name: string,
  capId: string,
}
enum InvigLang {
  FRENCH_INVIG = 'French Invigilation',
  ENGLISH_INVIG = 'English Invigilation',
  BILINGUAL_INVIG = 'Bilingual Invigilation (English and French) '
}

export interface ISessionTT {
  m: moment.Moment;
  id:number,
  sessionNum:number,
  instGroupId:number,
  testWindowTitle: string,
  dateDisplay?: string,
  timeFrom: string,
  institutionName?: string,
  testLocation?: string,
  hasAccessCode: boolean, 
  status: string,
  timeZone: string,
  isByod: boolean,
  isFull: boolean,
  isBookable?: boolean,
  isExpired?: boolean,
  isFilteredOut?:boolean,
  test_attempt_id?:number,
  delivery_format?:string,
  videostream_link?:string,
  videostream_password?:string,
  is_seb_disabled?:boolean,
  invigLang?:string,
  ts_invigLang?:string,
  //
  videostreamSafeUrl?:SafeResourceUrl, // tacked on in the client
  noShowStatus?: INoShowStatus;
  dateNoTime?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MyApplAccountService {

  constructor(
    private auth: AuthService,
    private loginGuard: LoginGuardService,
    private routes: RoutesService,
    private router: Router,
    private location: Location,
    private lang: LangService,
    private timezone: TimezoneService,
  ) { 
    this.init();
  }

  public isMakingApiReq:boolean = false;

  private info:BehaviorSubject<IMyApplAcct> = new BehaviorSubject(null);

  private init(){
    this.auth.user().subscribe(userInfo => {
      if(userInfo){
        this.info.next({
          uid: userInfo.uid,
          name: userInfo.firstName + ' ' + userInfo.lastName,
          capId: '224466',
        });
      }
      else{
        this.info.next(null);
      }
    })
  }

  public sub(){
    return this.info;
  }

  public getDisplayName(){
    return this.info.getValue().name
  }

  public async sendStripeSessionForAppeal() {
    const lang_code = this.lang.c();
    const currentDomain = window.location.href;
    let domainSplit = currentDomain.split('/');
    const attemptId = parseInt(domainSplit[domainSplit.length - 1]);
    const type = 'APPEAL';
    const body = {type, attemptId, lang_code};
    const stripeCheckout = await this.auth.apiCreate(
      this.routes.TEST_TAKER_TEST_SESSIONS_STRIPE_CHECKOUT,
      body
    );
    location.assign(stripeCheckout.url);
  }

  public async sendStripeSessionForCreditPurchase(test_session_id: number, access_code: string, lang: string, isAccommReq: boolean) {
    const lang_code = lang;
    const isAccomm = isAccommReq ? 1 : 0;
    const type = 'CREDIT_PURCHASE';
    const body = {test_session_id, type, lang_code, isAccomm}
    const stripeCheckout = await this.auth.apiCreate(
      this.routes.TEST_TAKER_TEST_SESSIONS_STRIPE_CHECKOUT,
      body
    );
    location.assign(stripeCheckout.url);
  }
  
  bookSession(test_session_id:number, access_code: string, paymentsEnabled?: boolean){
    if (!this.isMakingApiReq) {
      this.isMakingApiReq = true;
      return this.auth.apiCreate(
        this.routes.TEST_TAKER_TEST_SESSIONS_BOOKING,
        { test_session_id, access_code, lang: this.lang.c() }
      )
      .then((res) => {
        this.isMakingApiReq = false;
      })
      .catch( e => {
        this.isMakingApiReq = false;
        let caption = "";
        let props:any = {};
        switch(e.message){
          case("INVALID_ACCESS_CODE"):
            caption = 'msg_invalid_code';
            break;
          case("TEST_SESSION_FULL"):
            caption = 'msg_session_full';
            break;
          case("TEST_SESSION_BUFFER_CLOSED"):
            caption = 'msg_session_closed';
            break;
          case("EXISTING_BOOKING"):
            caption = 'msg_reg_error_completed';
            break;
          case("TEST_COMPLETED"):
            caption = 'msg_reg_error_completed';
            break;
          case("MPT_STATUS_NOT_ELIGIBLE"):
            caption = 'notif_restricted_booking_simple';
            break;
          case("NO_SHOW_LOCK"):
            caption = 'no_show_booking_err';
            props.can_book_on = processNoShowErrorDate(e, this.lang);
            props.no_show_buffer_days = e.data.noShowBufferDays;
            break;
        }
        this.loginGuard.confirmationReqActivate({caption: this.lang.tra(caption, null, props), confirm: ()=>{} })
        throw e;
      }) 
    }
    return Promise.resolve();
  }

  waitlistSession(test_session_id:number, access_code: string){
    if (!this.isMakingApiReq) {
      this.isMakingApiReq = true;
      return this.auth.apiCreate(
        this.routes.TEST_TAKER_TEST_SESSIONS_WAITLIST,
        { test_session_id, access_code, lang: this.lang.c()}
      )
      .then( () => {
        this.isMakingApiReq = false;
        this.gotoDashboard()
      })
      .catch( e => {
        this.isMakingApiReq = false;
        let caption = "";
        let props:any = {};
        switch(e.message){
          case("INVALID_ACCESS_CODE"):
            caption = 'msg_invalid_code';
            break;
          case("MAXIMUM_WAITLIST_REACHED"):
            caption = 'msg_reg_error_max_wait';
            break;
          case("NO_SHOW_LOCK"):
            caption = 'no_show_booking_err'; 
            props.can_book_on = processNoShowErrorDate(e, this.lang);
            props.no_show_buffer_days = e.data.noShowBufferDays;
            break;
          default:
            caption = 'msg_already_registered';
            this.loginGuard.confirmationReqActivate({caption, confirm: this.gotoDashboard })
            break;
        }
        this.loginGuard.confirmationReqActivate({caption, confirm: ()=>{} })
        throw e;
      })
    }
    return Promise.resolve();
  }

  gotoDashboard = () => {
    this.router.navigate([`/${this.lang.c()}/${AccountType.TEST_TAKER}/dashboard`]);
  }

  checkIfCreditsEnabled = () => {
    return this.auth.apiFind(this.routes.CREDITS_CREDIT_SYSTEM);
  }

  getUserCreditInfo = () => {
    return this.auth.apiFind(this.routes.TEST_TAKER_CREDIT_DETAILS);
  }

  checkIfPaymentsEnabled = () => {
    return this.auth.apiFind(this.routes.CREDITS_PAYMENT_SYSTEM);
  }

  sanitizeSessionInfo(session:ITestSessionAvailTTExt, institutionName:string = '') : ISessionTT {
    const m = this.timezone.moment(session.date_time_start);
    // '3:00pm, Sept. 16, 2020 (Wednesday)'
    const FMT_DATE_DAY_DISPLAY = this.lang.tra('datefmt_day_month_year_dow');
    const FMT_DATE_TIME_DISPLAY = this.lang.tra('datefmt_time');
    const FMT_DATE_TIME_ONLY = this.lang.tra('datefmtNoTime');
    let testWindowTitle;
    try {
      const titles = JSON.parse(session.test_window_title);
      testWindowTitle = titles[this.lang.c()];
    }
    catch(e){}

    let isBookable = true;
    if (session.pending !== undefined) {
      isBookable = session.pending === 0 ? true : false;
    }
    if(session.noShowStatus?.isLocked){
      session.noShowStatus.canBookOn = moment(session.noShowStatus.canBookOn).format(FMT_DATE_TIME_ONLY);
    }
    return {
        m,
        id:session.id,
        delivery_format: session.delivery_format,
        videostream_link: session.videostream_link,
        videostream_password: session.videostream_password,
        sessionNum:0,
        instGroupId:session.instit_group_id,
        dateDisplay: m.format(FMT_DATE_DAY_DISPLAY),
        timeFrom: m.format(FMT_DATE_TIME_DISPLAY),
        dateNoTime: m.format(FMT_DATE_TIME_ONLY),
        timeZone: this.timezone.getTimezone(),
        institutionName,
        testWindowTitle,
        status: session.status,
        is_seb_disabled: session.is_seb_disabled == 1 ? true: false,
        testLocation: [
          `${this.lang.tra('cts_location_room_lbl')} ${this.renderRoom(session)}, ${session.campus_building}`,
          `${session.address}`,
          `${session.city} ${session.province} ${session.postal_code}`,
          `${session.phone_number}`,
        ].join('\n'),
        hasAccessCode: session.is_access_code_enabled === 1, 
        isByod: session.delivery_format === EDeliveryOption.BYOD,
        isFull: session.booked ? session.booked >= session.capacity : false,
        isBookable: isBookable,
        invigLang: this.getInvigLangSlug(session.invigLang) || this.getInvigLangSlug(session.ts_invigLang),
        noShowStatus: session.noShowStatus
    }
  }

  private getInvigLangSlug(lang:string){
    if(lang == InvigLang.ENGLISH_INVIG) return this.lang.tra('lbl_en');
    if(lang == InvigLang.FRENCH_INVIG) return this.lang.tra('lbl_fr');
    if(lang == InvigLang.BILINGUAL_INVIG) return this.lang.tra('lbl_bil')

  }
  
  private renderRoom(session: ITestSessionAvailTT) {
    let room: string;
    if (session.delivery_format === 'remote') {
      room = this.lang.tra('tra_remote');
    } else {
      room = session.room;
    }
    return room;
  }

}

/**
 * This function is made to reduce duplicate code and process the no-show 10 days policy in one spot
 * 
 * @param e The NO_SHOW_LOCK full error object
 * @param lang this.lang, in order to use this.lang.tra
 * @returns This function returns the date where the applicant can book again formatted YYYY-DD-MM (en) and DD-MM-YYYY (fr)  
 */
export const processNoShowErrorDate = (e, lang) => {
  const FMT_DATE_TIME_ONLY = lang.tra('datefmtNoTime');
  const canBookOn = moment(e.data.canBookOn).format(FMT_DATE_TIME_ONLY);
  return canBookOn;
}