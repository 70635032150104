import { Injectable } from '@angular/core';
import { LangService } from './lang.service';
import { AccountType } from '../constants/account-types';

export interface IBreadcrumbRoute {
  route: string,
  caption: string,
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  constructor(
    private lang:LangService,
  ) { }

  private cLang(){
    return this.lang.getCurrentLanguage()
  }

  public learnMoreSection = {
    APPLICANT: 'applicant',
    TESTADMIN: 'testadmin',
  }

  _HEAD(accountType:AccountType){ 
    switch(accountType){
      case AccountType.TEST_CTRL: 
      case AccountType.CERT_BODY: 
      case AccountType.TEST_ADMIN: return this.TESTADMIN_LANDING();
      default:
      case AccountType.TEST_TAKER: return this.APPLICANT_LANDING();
    }
  }
  _CURRENT(caption:string, route:string) { return { caption, route } }
  HOME() {                return { caption: 'Home',                 route: '/'} }
  
  APPLICANT_LANDING() {   return { caption: this.lang.tra('title_teach_app'),   route: `/${this.cLang()}/applicant/landing`} }
  APPLICANT_EXPLORE_CONTENT() {   return { caption: this.lang.tra('mpt_title_explore_content'),   route: `/${this.cLang()}/applicant/landing`} }
  APPLICANT_BEFORE_TEST() {   return { caption: this.lang.tra('lm_title:applicant:before'),   route: `/${this.cLang()}/applicant/explore/before`} }
  APPLICANT_PT_MAIN() {   return { caption: this.lang.tra('title_sample_questions'),   route: `/${this.cLang()}/applicant/explore/before:practice`} }
  TESTADMIN_LANDING() {   return { caption: this.lang.tra('title_test_admins'), route: `/${this.cLang()}/testadmin/landing`} }
  APPLICANT_LEARN() { return { caption: this.lang.tra('mpt_title_explore_content'), route: `/${this.cLang()}/applicant/landing`} }
  TESTADMIN_LEARN() { return { caption: this.lang.tra('mpt_title_explore_content'), route: `/${this.cLang()}/testadmin/landing`} }
  APPLICANT_LEARNMORE() { return { caption: this.lang.tra('mpt_title_explore_content'), route: `/${this.cLang()}/applicant/explore`} }
  TESTADMIN_LEARNMORE() { return { caption: this.lang.tra('mpt_title_explore_content'), route: `/${this.cLang()}/testadmin/explore`} }
  APPLICANT_DASHBOARD() {   return { caption: this.lang.tra('title_dashboard'),   route: `/${this.cLang()}/test-taker/dashboard`} }
  TESTADMIN_DASHBOARD()   { return { caption: this.lang.tra('title_dashboard'), route: `/${this.cLang()}/${AccountType.TEST_ADMIN}/dashboard`} }
  TESTADMIN_VIEW_INVIG(test_session_id: number) { return { caption: this.lang.tra('cts_restricted_ts_title'), route: `/${this.cLang()}/test-admin/invigilate/${test_session_id}`}}
  SUPPORT_DASHBOARD()   { return { caption: this.lang.tra('title_dashboard'), route: `/${this.cLang()}/${AccountType.SUPPORT}/dashboard`} }
  
  TESTAUTH_DASHBOARD()   { return { caption: 'Authoring Dashboard', route: `/${this.cLang()}/${AccountType.TEST_AUTH}/dashboard`} }
  
  MRKG_CTRL_DASHBOARD()   { return { caption: 'Marking Controller Dashboard', route: `/${this.cLang()}/${AccountType.MRKG_CTRL}/dashboard`} }
  MRKG_SUPR_DASHBOARD()   { return { caption: 'Marking Supervisor Dashboard', route: `/${this.cLang()}/${AccountType.MRKG_SUPR}/dashboard`} }
  MRKG_MRKR_DASHBOARD()   { return { caption: 'Marker Dashboard', route: `/${this.cLang()}/${AccountType.MRKG_MRKR}/dashboard`} }
  MRKG_CAND_DASHBOARD()   { return { caption: 'Marking Candidate Dashboard', route: `/${this.cLang()}/${AccountType.MRKG_CAND}/dashboard`} }
  MRKG_UPLD_DASHBOARD()   { return { caption: 'Response Uploader Dashboard', route: `/${this.cLang()}/${AccountType.MRKG_UPLD}/dashboard`} }

  MRKG_COORD_DASHBOARD()   { return { caption: 'Marking Coordinator Dashboard', route: `/${this.cLang()}/${AccountType.MRKG_COORD}/dashboard`} }
  MRKG_LEAD_DASHBOARD()   { return { caption: 'Marking Leader Dashboard', route: `/${this.cLang()}/${AccountType.MRKG_LEAD}/dashboard`} }

  TESTADMIN_CREATE_TEST_SESSION(setupId:string, routeSuffix:string='test-window')   { return { caption: this.lang.tra('title_create_sessions'),          route: `/${this.cLang()}/${AccountType.TEST_ADMIN}/create-test-sessions/${setupId}/${routeSuffix}`} }
  TESTCTRL_DASHBOARD()   { return { caption: this.lang.tra('title_test_ctrl'), route: `/${this.cLang()}/${AccountType.TEST_CTRL}/dashboard`} }
  TESTCTRLD_DASHBOARD()   { return { caption: this.lang.tra('Data Specialists'), route: `/${this.cLang()}/${AccountType.TEST_CTRLD}/dashboard`} }
  LEARNMORE_ARTICLE(caption:string, basePath:string, articlePath:string) {   return { caption, route: basePath+'/'+articlePath } }

}
