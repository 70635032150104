<div class="page-body">
    <div>

      <header [breadcrumbPath]="breadcrumb" ></header>
      <div *ngIf="activeSessions.length > 0">
        <div class="today-sessions">
          <div class="panel-header"><tra slug="title_todays_sessions"></tra></div>
          <div>
            <markdown 
              class="markdown" 
              [data]="lang.tra('txt_ta_instr_open_invig')" 
            ></markdown>
          </div>
          <div class="session-card squishable" *ngFor="let session of activeSessions" [class.is-hidden]="session.isClosed">
            <div class="number-indic"> {{session.__num}} </div>
            <div >
              <div class="info-segment">
                <div style="font-size:1.2em;margin-bottom:0.3em;">{{session.testWindowTitle}}</div>
                <div class="info-segment-header"><tra slug="lbl_time_and_date"></tra>:</div>
                <div>{{renderLongTimestamp(session.dateTimeStart)}}</div>
              </div>
              <div class="info-segment" *ngIf="isRemote(session)">
                <span class="tag"><tra slug="lbl_rmt"></tra></span>
              </div>
              <div>
                <a [routerLink]="getPrintRoute(session.id)">
                  <i style="margin-right: 0.4em;" class="fa fa-print" aria-hidden="true"></i>
                  <tra slug="btn_print_list"></tra>
                </a>
              </div>
            </div>
            <div class="vert-stretch right-container-wide">
              <div class="bookings">
                <div>{{session.bookingsCount}}/{{session.capacity}} <tra slug="txt_snipp_booked"></tra></div>
                <progress 
                  class="progress is-small" 
                  [class.is-success]="session.bookingsCount == session.capacity"
                  value="{{session.bookingsCount}}" 
                  max="{{session.capacity}}"
                ></progress>
              </div>
              <a  [routerLink]="getInvigRoute(session.id)" *ngIf="verifyInvigilator(session)" class="button is-main is-disabled">
                <tra slug="btn_open"></tra>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div 
        class="page-content" 
        [class.is-sessions-upcoming]="getSetupState() == SETUP_STATES.SETUP_DONE" 
      >
        <div class="view-menu">
          <pnel-my-institution></pnel-my-institution>
        </div>
        <div  class="view-body">
          <div [ngSwitch]="getSetupState()">
            <div *ngSwitchCase="SETUP_STATES.LOADING">
              <tra slug="loading_caption"></tra>
            </div>
            <div *ngSwitchCase="SETUP_STATES.FAILED">
              <tra-md slug="txt_err_acct_no_access"></tra-md>
            </div>
            <div *ngSwitchCase="SETUP_STATES.SETUP_PENDING">
              <tra-md slug="txt_err_no_assigned_ts"></tra-md>
            </div>
            <div *ngSwitchCase="SETUP_STATES.NEW_SETUP">
              <h2><tra slug="page_ta_dashboard_1_title"></tra></h2>
              <div>
                <tra slug="page_ta_dashboard_1_main_txt"></tra>
              </div>
              <div class="button-main-row" >
                <button 
                  (click)="createNewTestSessionSetup()"
                  class="button is-main "
                >
                  <tra slug="page_ta_dashboard_1_getstarted_btn"></tra>
                </button>
              </div>
            </div>
            <div *ngSwitchCase="SETUP_STATES.RESUME_SETUP">
                <h2><tra slug="page_ta_dashboard_2_title"></tra></h2>
                <div>
                    <tra slug="page_ta_dashboard_2_main_txt"></tra>
                </div>
                <div class="button-main-row" >
                  <button 
                    (click)="resumeCreateNewTestSessions()"
                    class="button is-main "
                  >
                    <tra slug="page_ta_dashboard_2_btn"></tra>
                  </button>
                </div>
            </div>
            <div *ngSwitchCase="SETUP_STATES.SETUP_DONE">
              <div class="upcoming-sessions"> 
                
                <div class="panel-header">
                  <tra slug="title_test_sessions"></tra>
                </div>
                <p>
                  <tra slug="txt_instr_ta_scheduled_sessions"></tra>
                </p>
                <p *ngIf="upcomingSessions && (upcomingSessions.length == 0)">
                  <tra slug="txt_instr_ta_scheduled_sessions_none"></tra>
                </p>
                <p *ngIf="isSessionCreator()" style="margin-top:1em;">
                  <tra slug="txt_instr_ta_scheduled_sessions_sub"></tra>
                </p>

                <div class="info-container"  *ngIf="isSessionsLoaded">
                  <menu-bar [menuTabs]="menuTabs" [tabIdInit]="currentMenuTab"  (change)="currentMenuTab = $event"></menu-bar>
                  <div [ngSwitch]="currentMenuTab">

                    <div *ngSwitchCase="MENU_TAB.UPCOMING">
                      <table class="table is-condensed is-bordered">
                        <tr>
                          <th><tra slug="lbl_test_session_id"></tra></th>
                          <th>Description</th>
                          <th><tra slug="lbl_invig_lang"></tra></th>
                          <th><tra slug="lbl_delivery_method"></tra></th>
                          <th><tra slug="lbl_invigilator"></tra></th>
                          <th><tra slug="lbl_date_time"></tra></th>
                          <th><tra slug="lbl_stat"></tra></th>
                          <th style="max-width:8em">Actions</th>
                        </tr>
                        <tr *ngFor="let session of upcomingSessions" [class.is-test-day]="session.isTestDay">
                          <td >{{session.id}}</td>
                          <td >
                            <div>
                              <strong>{{session.testWindowTitle}} </strong>
                            </div>
                            <div *ngIf="!isRemote(session)">
                              (<tra slug="cts_location_room_lbl"></tra>: {{session.room}})
                            </div>
                          </td>
                          <td>{{getInvigLangSlug(session.invigLang)}}</td>
                          <td>{{getSessionDeliveryFormat(session.delivery_format)}}</td>
                          <td >{{getInvigilDisplay(session)}}</td>
                          <td class="session-indicator">{{renderShortTimestamp(session.dateTimeStart)}}</td>
                          <td>
                            <div class="bookings">
                              <div style="margin-bottom:0.5em; padding-left:0.2em;">
                                {{session.bookingsCount}}/{{session.capacity}} <tra slug="txt_snipp_booked"></tra>
                                <span 
                                  *ngIf="session.waitlistCount > 0" 
                                  style="margin-left:0.5em;" 
                                  class="tag"
                                >
                                  <span>{{session.waitlistCount}}&nbsp;</span>
                                  <tra slug="txt_snipp_on_waiting_list"></tra>
                                </span>
                              </div>
                              <progress 
                                class="progress is-small" 
                                [class.is-success]="session.bookingsCount == session.capacity"
                                value="{{session.bookingsCount}}" 
                                max="{{session.capacity}}"
                              ></progress>
                            </div>
                          </td>
                          <td>
                            <a [routerLink]="getBookingsRoute(session.id)" class="button is-info">
                              <tra slug="btn_manage_bookings"></tra>
                            </a>
                          </td>
                        </tr>
                      </table>
                      <div style="margin-top:0.5em;">
                        <tra-md slug="lbl_page_num" [props]="{num1: 1, num2: 1}"></tra-md>
                      </div>
                    </div>

                    <div *ngSwitchCase="MENU_TAB.PAST">

                      <table class="table is-condensed is-bordered">
                        <tr>
                          <th><tra slug="lbl_test_session_id"></tra></th>
                          <th>Description</th>
                          <th><tra slug="lbl_delivery_method"></tra></th>
                          <th><tra slug="lbl_invigilator"></tra></th>
                          <th><tra slug="lbl_date_time"></tra></th>
                          <th><tra slug="lbl_stat"></tra></th>
                          <th style="max-width:8em">Actions</th>
                        </tr>
                        <tr *ngFor="let session of unclosedTestSessions" [class.is-test-day]="session.isTestDay">
                          <td >{{session.id}}</td>
                          <td >
                            <div>
                              <strong>{{session.testWindowTitle}} </strong>
                            </div>
                            <div *ngIf="!isRemote(session)">
                              (<tra slug="cts_location_room_lbl"></tra>: {{session.room}})
                            </div>
                          </td>
                          <td>{{getSessionDeliveryFormat(session.delivery_format)}}</td>
                          <td >{{session.first_name}} {{session.last_name}}</td>
                          <td class="session-indicator">{{renderShortTimestamp(session.dateTimeStart)}}</td>
                          <td>
                              <span class="tag is-success"><tra slug="lbl_closed"></tra></span>
                          </td>
                          <td>
                            <a [routerLink]="getInvigilationsRoute(session.id)" class="button is-info">
                              <tra slug="btn_manage"></tra>
                            </a>
                          </td>
                        </tr>
                      </table>
                      <div style="margin-top:0.5em;">
                        <tra-md slug="lbl_page_num" [props]="{num1: 1, num2: 1}"></tra-md>
                      </div>

                    </div>


                    <div *ngSwitchCase="MENU_TAB.CALENDAR">
                      <div class="calendar-container" >
                        <!-- [class.is-hidden]="!events.length" -->
                        <full-calendar 
                          #calendar
                          defaultView="dayGridMonth" 
                          [plugins]="calendarPlugins"
                          [events]="calendarEvents"
                          [eventTimeFormat]="eventTimeFormat"
                          [locale]="getCalendarLocale()"
                        ></full-calendar>
                      </div>
                    </div>


                  </div>
                </div>

                <div *ngIf="isSessionCreator()" style="max-width: 58em; margin-top: 1em;">
                  <button (click)="createNewTestSessionSetup()" class="button is-main is-large " >
                    <tra *ngIf="setupInfo.__reqNew" slug="lbl_create_sessions"></tra>
                    <tra *ngIf="!setupInfo.__reqNew" slug="page_ta_dashboard_2_title"></tra>
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer [hasLinks]="true"></footer>
  </div>
