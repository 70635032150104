import * as moment from 'moment-timezone';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import { ScrollService } from '../../core/scroll.service';
import { LangService } from '../../core/lang.service';
import { TimezoneService } from '../../core/timezone.service';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
import { IInstitutionInfo, MyInstitutionService } from '../my-institution.service';
import { Subscription } from 'rxjs';
import { MyTestSessionsSetupService, ITestSessionSetupInfo } from '../my-test-sessions-setup.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { IAvailableSession } from '../demo-data.service';
import { AuthService } from '../../api/auth.service';
import { AvailableSessionsService } from '../available-sessions.service';
import enLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr-ca';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { IMenuTabConfig } from '../../ui-partial/menu-bar/menu-bar.component';

enum SETUP_STATES {
  LOADING      = 'LOADING',
  FAILED       = 'FAILED',
  NEW_SETUP    = 'NEW_SETUP',
  RESUME_SETUP = 'RESUME_SETUP',
  SETUP_PENDING   = 'SETUP_PENDING',
  SETUP_DONE   = 'SETUP_DONE',
}

enum MENU_TAB {
  UPCOMING = 'UPCOMING',
  PAST     = 'PAST',
  CALENDAR = 'CALENDAR',
}
enum InvigLang {
  FRENCH_INVIG = 'French Invigilation',
  ENGLISH_INVIG = 'English Invigilation',
  BILINGUAL_INVIG = 'Bilingual Invigilation (English and French) '
}
@Component({
  selector: 'view-dashboard',
  templateUrl: './view-dashboard.component.html',
  styleUrls: ['./view-dashboard.component.scss']
})
export class ViewDashboardComponent implements OnInit, OnDestroy {

  public breadcrumb = [];
  public instInfo:IInstitutionInfo;
  public setupInfo:ITestSessionSetupInfo;
  public SETUP_STATES = SETUP_STATES;
  public activeSessions:IAvailableSession[] = [];
  public upcomingSessions:IAvailableSession[] = [];
  public unclosedTestSessions = [];
  public isInited:boolean;
  public isRoleFailed:boolean;
  public showUnclosedTestSessions:boolean;
  public sessionCount:number = 0;
  public registrationCount:number = 0;
  public isLoading:boolean = true;
  
  private subs:Subscription[] = [];

  private hasPastSessions: boolean = false;
  // 
  

  currentMenuTab = MENU_TAB.UPCOMING;
  MENU_TAB = MENU_TAB;
  menuTabs:IMenuTabConfig<MENU_TAB>[] = [
    {id: MENU_TAB.UPCOMING, caption: this.lang.tra('lbl_upcoming')},
    {id: MENU_TAB.PAST, caption: this.lang.tra('lbl_past')},
    {id: MENU_TAB.CALENDAR, caption: this.lang.tra('lbl_cal')},
  ]


  // calendar
  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  calendarPlugins = [dayGridPlugin]; 
  eventTimeFormat: { // like '14:30:00'
    hour: '2-digit',
    minute: '2-digit',
    meridiem: true
  };
  calendarEvents = [ 
    // { title: 'L#1', date: '2020-01-10 09:00' }, 
  ];
  constructor(
    private auth: AuthService,
    private availSessService: AvailableSessionsService,
    private myInst: MyInstitutionService,
    private mySetup: MyTestSessionsSetupService,
    private loginGuard: LoginGuardService, // 
    private breadcrumbsService: BreadcrumbsService,
    private scrollService: ScrollService,
    public lang: LangService,
    public timezone: TimezoneService,
    private whitelabel:WhitelabelService,
    private router:Router,
  ) { }
  
  ngOnInit() {
    this.loginGuard.activate([AccountType.TEST_ADMIN]);
    this.scrollService.scrollToTop();
    this.breadcrumb = [
      this.breadcrumbsService.TESTADMIN_LANDING(),
      this.breadcrumbsService.TESTADMIN_DASHBOARD(),
    ];
    this.initRouteView();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe() );
  }
  
  initRouteView(){
    this.subs = this.subs.concat([
      this.myInst.sub().subscribe(this.updateInstInfo),
      this.myInst.fail().subscribe(this.onRoleFail),
      this.mySetup.sub().subscribe(this.updateSetupInfo)
    ]);
  }
  
  updateInstInfo = (instInfo:IInstitutionInfo) => {
    if (instInfo && !this.isInited){
      this.isInited = true;
      this.instInfo = instInfo;
      this.loadAvailableSessions();
    }
  }
  getMsAsDays = (ms:number) =>{
    let days = Math.floor(moment.duration(ms, 'milliseconds').asDays());
    return days || ' < 1';
  }

  onRoleFail = (failState:boolean) => {
    this.isRoleFailed = failState;
  }

  isShowBookingStats(){
    return this.isSessionCreator() && this.whitelabel.getSiteFlag('BOOKING_POLICY_STATS');
  }

  loadPrior() {
    this.auth.apiFind('public/test-admin/test-sessions/prior', {
      query: {
        instit_group_id: this.instInfo.groupId
      }
    }).then(res => {
      this.isLoading = false;
      if (res.data.length > 0 && !this.hasPastSessions) this.hasPastSessions = true;
      res.data.forEach((session) => {
        if (!this.hasPastSessions) return this.hasPastSessions = true;
        const start = this.timezone.moment(session.date_time_start);
        const now = moment.tz();
        if (start.isBefore(now)) {
          session.dateTimeStart = start; 
          this.unclosedTestSessions.push(session);
        }

      });
      //console.log(this.unclosedTestSessions);
    });
  }

  isSessionsLoaded:boolean;
  loadAvailableSessions() {
    this.availSessService.refresh();
    this.subs = this.subs.concat(this.availSessService.sub().subscribe(this.captureAvailableSessions));
    this.subs.concat(this.availSessService.sub().subscribe(this.captureAvailableSessions));
    this.loadPrior();
  }
  
  captureAvailableSessions = (availableSessions:IAvailableSession[]) => {
    // console.log('captureAvailableSessions', availableSessions, this.isSessionsLoaded)
    if (availableSessions && !this.isSessionsLoaded){
      this.isSessionsLoaded = true;
      const sessions = availableSessions;
      sessions.forEach(session => {
        this.sessionCount ++;
        //console.log('session', session)
        this.registrationCount += session.bookingsCount || 0;
        if (session.__isStartingSoon && !session.is_closed){
          this.activeSessions.push(session);
        }
        if(!session.is_closed){this.upcomingSessions.push(session);}
        this.calendarEvents.push({
          title: this.lang.tra('cts_location_room_lbl') + ' ' + session.room,
          date: session.dateTimeStart.format('YYYY-MM-DD HH:mm')
        })
      })
      this.calendarEvents = [].concat(this.calendarEvents);
      // console.log('update calendarEvents', this.calendarEvents)
    }
  }
  verifyInvigilator(session:IAvailableSession){
    const currUserId = this.auth.user().value.uid;
    return session.inivigilatorId == currUserId || this.myInst.isInstMngr();
  }

  getPrintRoute(sessionId:number){
    return  `/${this.lang.c()}/${AccountType.TEST_ADMIN}/print-session/${sessionId}`
  }
  
  renderLongTimestamp(m:moment.Moment){
    return m.format( this.lang.tra('datefmt_dashboard_long'));
    // 4:30pm, Sept. 22, 2020 (Wednesday)
  }

  renderShortTimestamp(m:moment.Moment){
    return m.format( this.lang.tra('datefmt_dashboard_short'));
  }

  renderCapacityProportion(session:IAvailableSession){
    return Math.round(100 * session.bookingsCount / session.capacity);
  }

  updateSetupInfo = (setupInfo:ITestSessionSetupInfo) => {
    this.setupInfo = setupInfo;
  }

  getInvigilDisplay(session:IAvailableSession){
    return this.availSessService.renderInvigDisplayFromSession(session, false);
  }

  getCalendarLocale(){
    if (this.lang.c() === 'fr'){
      return frLocale;
    }
    else{
      return enLocale;
    }
  }

  getInvigLangSlug(lang:string){
    if(lang == InvigLang.ENGLISH_INVIG) return this.lang.tra('lbl_en')
    if(lang == InvigLang.FRENCH_INVIG) return this.lang.tra('lbl_fr')
    if(lang == InvigLang.BILINGUAL_INVIG) return this.lang.tra('lbl_bil')
  }

  getSessionDeliveryFormat(caption: string) {
    if (caption == 'computer-lab') return this.lang.tra('CAEC_tc_computer_lab')
    if (caption == 'paper-based') return this.lang.tra('CAEC_tc_paper_based')
    return caption;
  }

  getSetupState(){
    // console.log('getSetupState', this.isRoleFailed, this.SETUP_STATES.FAILED)
    if (this.isRoleFailed){
      return this.SETUP_STATES.FAILED;
    }
    else if (this.isLoading) return this.SETUP_STATES.LOADING;
    else if (this.myInst.isInstMngr()){
      if (this.setupInfo){
        // console.log('has setup info')
        if (this.myInst.hasSessions() || this.hasPastSessions){
          return SETUP_STATES.SETUP_DONE
        }
        if (this.myInst.isInstMngr()){
          if (!this.hasPastSessions) {
            if (this.setupInfo.__reqNew){
              return this.SETUP_STATES.NEW_SETUP;
            }
            else{
              return this.SETUP_STATES.RESUME_SETUP;
            }
          }
        }
        else{
          return this.SETUP_STATES.SETUP_PENDING;
        }
      }
    }
    else {
      return SETUP_STATES.SETUP_DONE
    }
    return this.SETUP_STATES.LOADING;
  }
  createNewTestSessionSetup(){
    this.mySetup.createNewTestSessionSetup();
  }

  isSessionsActive(){
    return false;
  }

  isSessionsUpcoming(){
    return true;
  }

  isSessionCreator(){
    return this.myInst.isInstMngr(); // accomm coord can do it in the pending accomm view
  }

  resumeCreateNewTestSessions(){
    this.mySetup.navToLastVisitedCS(this.setupInfo);
  }

  getInvigRoute(sessionId:number) {
    return `/${this.lang.c()}/${AccountType.TEST_ADMIN}/invigilate/${sessionId}`;
  }

  getBookingsRoute(sessionId:number) {
    return `/${this.lang.c()}/${AccountType.TEST_ADMIN}/manage-session/${sessionId}`;
  }

  getInvigilationsRoute(sessionId: number) {
    return `/${this.lang.c()}/${AccountType.TEST_ADMIN}/invigilate/${sessionId}`;
  }

  isRemote(session: IAvailableSession): boolean {
    return session.delivery_format === 'remote';
  }
}
