import { Component, OnInit, Input, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ElementType, QuestionState } from '../models';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'

export const memo = <T>(map: Map<string, T>, key:string, generator: (key:string) => T ) => {
  if (map.has(key)){
    return map.get(key);
  }
  else{
    const val = generator(key);
    map.set(key, val);
    return val;
  }
}

const typesFromExt = {mp4: 'video/mp4', webm: 'video/webm', ogg: 'video/ogg'};
const MP4_BUCKET = 'https://d3azfb2wuqle4e.cloudfront.net';

@Component({
  selector: 'element-render-video',
  templateUrl: './element-render-video.component.html',
  styleUrls: ['./element-render-video.component.scss']
})
export class ElementRenderVideoComponent implements OnInit {

  @ViewChild('video')  video: ElementRef
  @ViewChild('videoTooltip')  videoTooltip: ElementRef
  @ViewChild('videoPlayer') videoPlayer: ElementRef
  @Input() element:any;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  public plyrOptions = {
    controls: [
      'play-large', 
      'play', 
      'progress', 
      'current-time', 
      'mute', 
      'volume', 
      'captions', 
      'settings', 
      'fullscreen'
    ]
  }
  public videoSourceUrls:string[] = [];
  private sanitizedUrls:Map<string, SafeResourceUrl> = new Map();
  private videoSources:Map<string, any[]> = new Map();
  private subtitleTracks:Map<string, any[]> = new Map();
  canStartPlayingCalled: boolean = false;
  isTooltipVisible: boolean = false;
  allowSubtitles: boolean = false;
  isLimitted: boolean = false;
  isAutoPlay: boolean = false;
  isPlaying: boolean = false;
  resetter: boolean = true;
  timeSet: boolean = false;
  paused: boolean = false;
  startTimeStamp: number = 0;

  constructor(
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initVars();
  }

  getSubtitleTracks(){
    return memo(this.subtitleTracks, this.element.subtitlesUrl, src => [{
      kind: 'captions',
      label: 'Enabled',
      // srclang: 'en',
      src,
      default: true,
    }]);
  }

  private isSafari(){
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf("Safari") > -1;
  }
  
  canStartPlaying() {
    if (!this.canStartPlayingCalled && this.questionState && this.canPlay()) {
      const id = this.element.entryId
      const el = this.getVideoElement()
      const ts = this.questionState[id].timeStamp;
      this.startTimeStamp = ts;
      if (!el) return;
      if (ts && ts>0 && ts<el["duration"]) {
        this.isAutoPlay = true;
        el["currentTime"] = ts;
        el.play();
      }
      this.canStartPlayingCalled = true;
    }
    
  }

  optimizeTypes(urls:string[]) {
    const optimized = [];

    for(let src of urls) {
      if(!src) {
        continue;
      }
      const ext = src.split('.').pop();
      const type = typesFromExt[ext];

      if (type) {
        optimized.push({ src: (ext == "mp4" ? this.optimizeUrl(src) : src), type });
      }
    }
    return optimized;
  }

  loadedMetaData() {
    if (this.isSafari()) {
      this.canStartPlaying();
    }
  }

  // ZoomService is not yet brought to MPT, keeping at 1 until we revisit
  getZoom = () => 1;

  playing() {  
    this.isPlaying = true;
    if(this.questionState && this.questionState[this.element.entryId]){
      this.questionState[this.element.entryId].isResponded = true;
      if(this.questionState && this.questionState[this.element.entryId].numViews === 0) {
        this.isTooltipVisible = true;
      } 
    }
  }
  
  fullScreen() {
    const elem = this.getVideoElement()
    if (elem && this.element.allowFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  }

  play() {
    this.isPlaying = true;
    this.isAutoPlay = false;
    const el:HTMLMediaElement = this.getVideoElement();
    if (!el) return;
    if (this.canPlay()) {
      el.play();
      this.paused? '': this.addView();
      this.paused = false
    }
  }
  
  pause() {
    const el:HTMLMediaElement = this.getVideoElement();
    if (!el) return;
    if (!this.element.isUnPausable) {
      this.paused = true;
      el.pause();
    }
  }

  notPlaying(logTime:boolean = true) {
    this.isPlaying = false;
    this.isAutoPlay = false;
    if (logTime) {
      if (this.questionState && this.questionState[this.element.entryId]) {
        this.questionState[this.element.entryId].pauseTimes.push(this.getTime());
      }
    }
  }

  addView() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const state = this.questionState[this.element.entryId]
      const el = this.getVideoElement();
      if (!el) return;
      if (!this.isAutoPlay && (!state.timeStamp || state.timeStamp==0 || state.timeStamp==el["duration"])){
        state.numViews++;
        state.timeStamp = 0;
        this.resetFlag();
      }
    }
  }

  hasStarted() {
    return this.isPlaying;
  }

  initVars() {
    const id = this.element.entryId;
    if (this.questionState){
      if (this.questionState[id]) {
        const qState = this.questionState[id];
        if (!qState.seekTimes) {
          qState.seekTimes = [];
        }
        if (!qState.loadTimes) {
          qState.loadTimes = [];
        }
        if (!qState.pauseTimes) {
          qState.pauseTimes = [];
        }
        if (!qState.endedTimes) {
          qState.endedTimes = [];
        }
      }
      this.questionState[id].loadTimes.push(this.getTime());
    }

    this.videoSourceUrls = this.getVideoSources();
  }

  onSeeking() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      if (!this.questionState[this.element.entryId].seekTimes) {
        this.questionState[this.element.entryId].seekTimes = []
      }
      this.questionState[this.element.entryId].seekTimes.push(this.getTime())
    }
  }

  resetFlag() {
    this.resetter = false;
    this.changeDetector.detectChanges();
    this.resetter = true;
  }

  onTimeUpdate() {
    if (this.questionState && this.questionState[this.element.entryId]) {
      const element = this.getVideoElement();
      if (!element) return;
      const state = this.questionState[this.element.entryId]
      if (state.timeStamp && state.timeStamp > element["currentTime"] && !this.timeSet) {
        element["currentTime"] = state.timeStamp
        this.timeSet = true
      } else {
        state.timeStamp = element["currentTime"]
      }
    }
  }

  getTime() {
    const d = new Date();
    return d.getTime();
  }

  ended() {
    this.notPlaying(false);
    if (this.questionState && this.questionState[this.element.entryId]) {
      this.questionState[this.element.entryId].endedTimes.push(this.getTime());
    }
    console.log("ended times", this.questionState[this.element.entryId].endedTimes)
    if (this.element.onVideoEnd) {
      this.element.onVideoEnd.forEach(pub => {
        // The commented line below is connected to bringing over another component, for now we only need multi-format processing
        // this.questionPubSub.elementPub(+pub.entryId, pub.type, pub.data); 
      });
    }
  }

  getVideoElement() {
    let el = null;
    
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      el = this.videoPlayer.nativeElement
    }
    return el;
  }

  canPlay() {
    const state = this.ensureState();
    const el = this.getVideoElement()
    if (!el) {
      return false;
    }
    if (state){
      if (this.element.maxViews && ((state["timeStamp"] == 0 || state["timeStamp"].toFixed(3)==el["duration"].toFixed(3))&& state.numViews >= this.element.maxViews)){
        return false;
      }
      else{
        return true;
      }
    }
    return false;  
  }

  ensureState() {
    if (this.questionState && !this.questionState[this.element.entryId]) {
      const state = {
        isStarted: true,
        isFilled: true,
        isResponded: false,
        numViews: 0,
        timeStamp: 0,
        type: ElementType.VIDEO
      };
      if (!state.numViews) {
        state.numViews = 0;
      }
      this.questionState[this.element.entryId] = state;
    }
    if (this.questionState){
      return this.questionState[this.element.entryId];
    }
    else{
      return {}
    }
  }

  getVideoSources() {
    if(this.element.urls && this.element.urls.length > 0) {
      return this.optimizeTypes(this.element.urls);
    }

    const filePath = this.element.url.split(MP4_BUCKET).pop();
    const file = filePath.split('/').pop();

    const altUrlAuto   = `${MP4_BUCKET}/video${filePath}.webm`;
    const altUrlManual = `${MP4_BUCKET}/video/${file}.webm`;
    return this.optimizeTypes([this.element.url, altUrlAuto, altUrlManual]);
  }

  getVideoSources_deprecated(){
    return memo(this.videoSources, this.element.url, src => [{ src: this.optimizeUrl(src), type: 'video/mp4' }]);
  }

  optimizeUrl(url:string){
    const domainRaw:string = 'https://s3.ca-central-1.amazonaws.com/authoring.mathproficiencytest.ca/';
    const domainCdn:string = 'https://d3azfb2wuqle4e.cloudfront.net/';
    return url.split(domainRaw).join(domainCdn);
  }

  getVideoUrl(){
    return memo(this.sanitizedUrls, this.element.url, rawUrl => this.sanitizer.bypassSecurityTrustResourceUrl(this.optimizeUrl(rawUrl)) );
  }

}
