<div class="page-body">
  <div>
    <header
    [breadcrumbPath]="breadcrumb"
    ></header>
    <div class="page-content is-fullpage" >
      <div *ngIf="isFeatureDisabled">
        <tra slug="registration_closed"></tra>
        <!-- <b><h3 style="text-align: center;"><tra slug="sys_application_unavailable"></tra></h3></b>
        <hr>
        <img [src]="lang.tra('oct_logo')"/>
        <h3 class="sys-outage-title"><tra slug="sys_applicant_reg_title"></tra></h3>
        <div class="sys-outage-body"><tra-md slug="sys_applicant_reg_body"></tra-md></div> -->
        <!-- <div class="notification is-warning">
          <tra-md slug="txt_applicant_registration_upcoming"></tra-md>
        </div> -->
      </div>
      <div class="form-content" *ngIf="!isFormValidated && !isFeatureDisabled">
        <h2> <tra slug="create_acct_mpt_applicant"></tra> </h2>
        <div *ngIf="isLoading">
          <tra slug="loading_caption"></tra>
        </div>
        <div *ngIf="!isLoading">

          <div class="form-instruction-major">
            <tra slug="create_acct_tt_instr"></tra>
          </div>

          <div class="field">
            <label for="collegeApplicationNumber" class="label"> <tra slug="create_acct_tt_cap"></tra></label>
            <div class="control">
              <input id="collegeApplicationNumber" aria-label="collegeApplicationNumber" class="input" [formControl]="formGroup.controls.collegeApplicationNumber">
            </div>
            <div class="form-instruction-minor">
              <markdown 
                class="markdown" 
                [data]="lang.tra('txt_cap_instr')" 
                ngPreserveWhitespaces
              ></markdown>
            </div>
          </div>

          <hr/>

          <div class="field">
            <label for="givenName" class="label"><tra slug="mpt_create_acct_first_name_lbl"></tra> </label>
            
            <div class="control">
              <input id="givenName" class="input" [formControl]="formGroup.controls.givenName">
            </div>
          </div>

          <div class="field">
            <label for="preferredName" class="label"><tra slug="mpt_create_acct_preferred_name_lbl"></tra> </label>
            <div class="control">
              <input id="preferredName" class="input" [formControl]="formGroup.controls.preferredName">
            </div>
          </div>

          <div class="field">
              <label for="surname" class="label"><tra slug="create_acct_last_name_lbl"></tra> </label>
            <div class="control">
              <input id="surname" class="input" [formControl]="formGroup.controls.surname">
            </div>
          </div>

          <div class="field">
            <label for="applicantType" class="label"><tra slug="lbl_appl_reg_applic_juris"></tra> </label>
            <div class="control">
              <div class="select is-fullwidth">
                <select id="applicantType" [formControl]="formGroup.controls.applicantType">
                  <option selected> --- </option>
                  <option *ngFor="let level of applicantTypes" [value]="level.slug">{{level.caption}}</option>
                </select>
              </div>
            </div>
          </div>
          
          <div class="notification" *ngIf="isLabourMobilityApplicant()">
            <tra-md slug="txt_reg_appl_check_req"></tra-md>
          </div>

          <div class="field" *ngIf="isOntarioApplicant()">
            <label for="teacherEdProg" class="label"><tra slug="lbl_appl_reg_applic_faculty"></tra> </label>
            <div class="control">
              <div class="select is-fullwidth">
                <select id="teacherEdProg" [formControl]="formGroup.controls.teacherEdProg">
                  <option selected> --- </option>
                  <option *ngFor="let prog of teacherEdProgs" [value]="prog.slug">{{prog.caption}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field" *ngIf="isOntarioApplicant()">
            <label for="studyLevel" class="label"><tra slug="lbl_appl_reg_is_yos"></tra> </label>
            <div class="control">
              <div class="select is-fullwidth">
                <select id="studyLevel" [formControl]="formGroup.controls.studyLevel">
                  <option selected> --- </option>
                  <option *ngFor="let level of studyLevels" [value]="level.slug">{{level.caption}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field" *ngIf="isInternationalApplicant()">
            <label for="internationalCountry" class="label"><tra slug="lbl_appl_reg_int_country"></tra> </label>
            <div class="control">
              <div class="select is-fullwidth">
                <select id="internationalCountry" [formControl]="formGroup.controls.internationalCountry">
                  <option selected> --- </option>
                  <option *ngFor="let country of countries" [value]="country.slug">{{country.caption}}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="field" *ngIf="isLabourMobilityApplicant()">
            <label for="province" class="label"><tra slug="lbl_appl_reg_province"></tra> </label>
            <div class="control">
              <div class="select is-fullwidth">
                <select id="province" [formControl]="formGroup.controls.province">
                  <option selected> --- </option>
                  <option *ngFor="let province of provinces" [value]="province.slug">{{province.caption}}</option>
                </select>
              </div>
            </div>
          </div>
          
          <div class="field" *ngIf="isOntarioApplicant() || isCurrentMember()">
            <label for="teacherProgram" class="label"><tra slug="lbl_appl_reg_teach_prog"></tra> </label>
            <div class="control">
              <!-- <input class="input" [formControl]="formGroup.controls.surname"> -->
              <div class="select is-fullwidth">
                <select id="teacherProgram" [formControl]="formGroup.controls.teacherProgram">
                  <option selected> --- </option>
                  <option *ngFor="let level of teachingLevels" [value]="level.slug">{{level.caption}}</option>
                </select>
              </div>
            </div>
          </div>
          
          <div class="field" *ngIf="isOntarioApplicant() || isCurrentMember()">
            <label class="label">
              <tra slug="lbl_appl_reg_teachables"></tra> 
              <tra style="font-weight:300; margin-left:0.5em;" slug="txt_select_all_apply"></tra> 
            </label>
            <!-- <div> <small> <em> As per O. Reg. 176/10, s. 50, Sched. A; O. Reg. 239/14, s. 12; O. Reg. 248/16, s. 11. </em> </small> </div> -->
            <div class="small-header">
              <tra slug="data_teachables_sched_a"></tra>
            </div>
            <div class="checkbox-wrap-container">
              <label class="checkbox" *ngFor="let teachable of teachables1">
                <div class="input-wrapper">
                  <input type="checkbox" [formControl]="teachable.fc" >
                </div>
                <tra [slug]="teachable.caption"></tra>
              </label>
            </div>
            <div class="small-header">
              <tra-md slug="data_teachables_sched_b"></tra-md>
            </div>
            <div  class="checkbox-wrap-container" >
              <label class="checkbox" *ngFor="let teachable of teachables2">
                <div class="input-wrapper">
                  <input type="checkbox" [formControl]="teachable.fc" >
                  </div>
                <tra [slug]="teachable.caption"></tra>
              </label>
            </div>
      
          </div>

          <hr/>

          <div class="field">
            <label for="emailAddress" class="label">
              <tra slug="create_acct_email_lbl"></tra>
            </label>
            <div class="control">
              <input id="emailAddress" class="input" [formControl]="formGroup.controls.emailAddress">
            </div>
            <div class="form-instruction-minor">
              <tra slug="create_acct_email_instr"></tra>
            </div>
          </div>

            <div class="field">
            <label for="password" class="label">
              <tra slug="create_acct_password_cr_lbl"></tra>
            </label>
            <div class="control">
              <input id="password" class="input" type="password" [formControl]="formGroup.controls.password">
            </div>
            <div>
              <button class="button is-small is-fullwidth" (click)="togglePasswordPolicy()">
              <tra *ngIf="!isPasswordPolicyShowing" slug="btn_view_pass_policy"></tra>
              <tra *ngIf="isPasswordPolicyShowing" slug="btn_hide_pass_policy"></tra>
              </button>
              <div *ngIf="isPasswordPolicyShowing" class="read-detail">
              <markdown 
                class="markdown" 
                [data]="lang.tra('txt_password_advice_and_policy')" 
                ngPreserveWhitespaces
              ></markdown>
              </div>
            </div>
            </div>

          <div style="margin-bottom:2em;">
            
          </div>

          <div class="field">
            <label for="passwordConfirm" class="label">
              <tra slug="create_acct_password_cnf_lbl"></tra>
            </label>
            <div class="control">
              <input id="passwordConfirm" class="input" type="password" [formControl]="formGroup.controls.passwordConfirm">
            </div>
          </div>

          <hr/>

          <div style="margin-bottom:1em;">
            <tra-md slug="lbl_attestation"></tra-md>
          </div>
<!-- 
          <div class="read-detail">
            <tra-md slug="txt_attestation"></tra-md>
          </div> -->

          <!-- <div class="field">
            <div class="control">
              <input  type="checkbox" [formControl]="formGroup.controls.attestation" style="margin-right:1em;">
              <tra slug="lbl_agree"></tra>
            </div>
          </div> -->
    

          <div *ngIf="isFormFailed" class="notification is-warning">
            <tra-md *ngIf="!isRefreshingFailReason" [slug]="formFailReason"></tra-md>
          </div>

          <div>
            <button [disabled]="isSubmitting" (click)="submitForm()" class="button is-success is-large is-fullwidth">
              <tra slug="create_acct_submit_btn"></tra>
            </button>
          </div>


        </div>
      </div>
      <div class="form-content" *ngIf="isFormValidated">
        <h2> <tra slug="create_acct_created_main_txt"></tra> </h2>
        <div class="form-instruction-major">
          <tra slug="create_acct_created_tt_main_instr"></tra>
        </div>
        <div class="form-popout-info">
          <div><tra slug="create_acct_created_email_txt1"></tra></div>
          <div>
            <a>{{emailUsed}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer [hasLinks]="true"></footer>
</div>
