import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
import { IInstitution } from '../../api/models/db/institutions.schema';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import * as moment from 'moment-timezone';

interface IVerificationQueryParams{  
  $limit: number,
  $skip: number,
  instit_group_id?: number
}

@Component({
  selector: 'view-tc-technical-readiness-verification',
  templateUrl: './view-tc-technical-readiness-verification.component.html',
  styleUrls: ['./view-tc-technical-readiness-verification.component.scss']
})
export class ViewTcTechnicalReadinessVerificationComponent implements OnInit {


  public verificationResults:any[];
  public institutionResults:IInstitution[];

  constructor(
    private auth : AuthService,
    private routes : RoutesService,
    ) { }
  
  isFailed:boolean;
  failReason:string;
  currentPage:number = 0;
  totalPages:number = 0;
  totalDataCount:number = 0;
  Math = Math;
  currentSelectedInstitution = new FormControl();

  ngOnInit() {
    this.getInstitutionInfo();
    this.getVerificationResults();
  }

  getInstitutionInfo(){
    return this.auth
      .apiFind(
        this.routes.TEST_CTRL_ACCOUNTS_INSTITUTIONS,
        {}
      )
      .then( (instRes:{data:IInstitution[]}) => {
        this.institutionResults = instRes.data;
      })
      .catch((err:any)=>{
        this.isFailed = true;
        this.failReason = err.message;
      })      
  }

  filterByInstitution(){
    if(this.currentSelectedInstitution.value){
      this.currentPage = 0;
      this.getVerificationResults(this.currentSelectedInstitution.value.group_id);
    }else{
      this.getVerificationResults();
    }
  }

  pageShift(num:number){
    this.currentPage += num;
    if(this.currentSelectedInstitution.value){
      this.getVerificationResults(this.currentSelectedInstitution.value.group_id);
    }else{      
      this.getVerificationResults();
    }
  }

  isMin(){
    return this.currentPage === 0;
  }
  isMax(){
    return this.currentPage >= this.totalPages;
  }
  downloadForm(entry){    
    this.isFailed = false;
    this.auth
    .apiFind(
      this.routes.TEST_CTRL_TECHNICAL_READINESS_FORM_DOWNLOAD,
      {
        query:{
          form_location: entry.form_location
        }
      }
    )
    .then((data:any) => {
      if(data.length < 1){
        throw new Error('Download URL missing')
      }
      window.open(data[0].url, '_blank')
    })
    .catch((err:any)=>{
      this.isFailed = true;
      this.failReason = err.message;
    })
  }

  getVerificationResults(instit_group_id?:number){      
    this.isFailed = false;
    let query:IVerificationQueryParams = {
      $limit: 10,
      $skip: this.currentPage*10,
    }
    if(instit_group_id){
      query.instit_group_id = instit_group_id;
    }
    this.auth
    .apiFind(
      this.routes.TEST_CTRL_TECHNICAL_READINESS_VERIFICATION, { query }
    )
    .then((data:any) => {
      this.totalDataCount = data.total;
      this.verificationResults = data.data;
      this.totalPages = Math.floor(data.total/10);
    })
    .catch((err:any)=>{
      this.isFailed = true;
      this.failReason = err.message;
    })
  }
  
  verifyEntry(entry, verfiy:boolean){  
    this.isFailed = false;
    this.auth
    .apiCreate(
      this.routes.TEST_CTRL_TECHNICAL_READINESS_VERIFY_FORM,
      {
        test_session_setups_id: entry.id,
        isVerified: verfiy
      }
    )
    .then((data:any) => {
      this.getVerificationResults(this.currentSelectedInstitution.value.group_id);
    })
    .catch((err:any)=>{
      this.isFailed = true;
      this.failReason = err.message;
    })
  }

  renderDateFromStr(dateString:string){
    //don't attempt to format null values.
    if(!dateString){ 
      return ""
    }
    return moment(dateString).format('MMM D, YYYY [at] h:mma');
  }
}
