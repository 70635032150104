<div class="dont-print">
  <div class="slab-lang">
    <button
      *ngFor="let lang of langs"
      (click)="setLang(lang.code)"
      class="button is-small"
      [class.is-light]="!isLangActive(lang.code)"
      [class.is-info]="isLangActive(lang.code)"
    >{{lang.caption}}</button>
    <button
      *ngIf="langService.isUsingLiveTranslations()"
      class="button is-small is-danger"
      (click)="langService.deactivateLiveTranslations()"
    >LIVE UPDATE</button>
    <span>
      <img class="logo" [src]="displayLogo()" alt="eqao_logo"/>
    </span>
  </div>
  <div class="slab-site-account">
    <div class="site-title">
      <a style="color: #222;" [routerLink]="getHomeScreenRoute()" [class.no-pointer-events]="isUsingSebSoftware()">
        <tra [slug]="getBranding()"></tra>
      </a>
    </div>
    <div class="profile-formlet">
      <div *ngIf="!userInfo">
        <a [routerLink]="getLoginRoute()" >
          <tra slug="title_login"></tra>
        </a>
      </div>
      <div *ngIf="userInfo">
        <div [ngSwitch]="!!accountInfo" class="user-display-name">

          <span *ngIf="newMessage" (click)="onClickNewMessage()">
            <a [routerLink]="'/en/chat/' + messagePoolId"> <i class="fa fa-envelope" aria-hidden="true"></i> </a>|
          </span>

          <span *ngSwitchCase="true"> <a [routerLink]="accountInfo">{{getUserDisplayName()}}</a>  </span>
          <span *ngSwitchCase="false">{{getUserDisplayName()}}</span>
        </div>
        <div style="margin-bottom: 1em; opacity:0.8; font-size:0.7em;">
          <div><b><tra slug="title_timezone"></tra> </b>{{timeZone}}</div>
        </div>
        <div>
          <button class="button is-small is-outlined is-danger" style="margin-right:0em ;" (click)="logout()">
              <tra slug="title_logout"></tra>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="color-strip" [ngStyle]="{'background-color':getStripColor()}"></div>
  <div class="slab-breadcrumb" [class.no-pointer-events]="isUsingSebSoftware()">
    <div class="crumb-element">
      <a class="crumb" (click)="toggleMenu()" style="font-weight:600" *ngIf="isBreadcrumbMenuEnabled()">Menu</a>
      <span *ngIf="breadcrumbPath && breadcrumbPath.length" class="crumb-delim">/</span>
    </div>
    <div class="crumb-element" *ngFor="let crumb of breadcrumbPath; let isLast = last">
      <a class="crumb" [routerLink]="getCrumbRoute(crumb)">{{crumb.caption}}</a>
      <span *ngIf="!isLast" class="crumb-delim">/</span>
    </div>
  </div>
  <div *ngIf="isMenuRevealed" class="slab-breadcrumb buttons" style="border-top:1px solid #ccc;" [class.no-pointer-events]="isUsingSebSoftware()">
    <a class="button is-small" routerLink="/{{langService.c()}}/applicant/landing"><tra slug="tab_test_app"></tra></a>
    <a class="button is-small" routerLink="/{{langService.c()}}/testadmin/landing"><tra slug="tab_test_admin"></tra></a>
  </div>
</div>
