<div class="formula-sheet">
  <div class="header-container">
    <tra class="main" slug="form_sheet_title"></tra><br>
    <tra class="sub" slug="form_sheet_sub_title"></tra>
  </div>
  <div *ngIf="getShapesAudioUrl() && isAudioPlayerShowing()" class="dont-print">
    <render-audio
            [url]="getShapesAudioUrl()"
            [hasControls]="true"
    ></render-audio>
  </div>
  <element-render-image [element]="getPage1Element('formula_sheet_img_p1')" [questionState]="{}" ></element-render-image>
  <div *ngIf="getSolidsAudioUrl() && isAudioPlayerShowing()" class="dont-print">
    <render-audio
            [url]="getSolidsAudioUrl()"
            [hasControls]="true"
    ></render-audio>
  </div>
  <element-render-image [element]="getPage1Element('formula_sheet_img_p2')" [questionState]="{}" ></element-render-image>
</div>