import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { applySecretKeyMask } from '../core/secret-key-mask';
import { randomFirstName, randomLastName, randomName, randomIdentity } from '../constants/fakenames';
import { _SAMPLE_INVITATION_CODES } from '../constants/fakeinvitations/codes';
import { IAccountInfoExt as IAccountInfo } from './view-mng-inst-accounts/view-mng-inst-accounts.component';
import { AuthService, IUserInfo } from '../api/auth.service';
import { FormControl } from '@angular/forms';

// This service can be used to mix demo data with real-time database data as required

// INTERFACES
export interface IAccountFakeInfo {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  isAccommCoord: boolean,
  numSessions: number,
  accountCreatedOn: moment.Moment,
  invitationCode: string,
  invitationName: string,
  invitationEmail: string,
  accessGrantedOn: moment.Moment,
  isInviteUsed: boolean,
}

export interface IBooking {
  __isSelected?: FormControl,
  id?: number,
  attemptId?: number,
  name?: string,
  firstName?: string,
  preferredName?: string,
  studyYear?: string,
  lastName?: string,
  nameSortable?: string,
  email?: string,
  langReq?: string,
  oct_id?: string,
  timestamp?: moment.Moment,
  reqTransferTimestamp?: moment.Moment,
  uid?: number,
  status?: string,
  accomRequired?: boolean,
  date_time_start?:  moment.Moment,
  test_session_id?: number,
  campus_building?:string,
  room?:string,
  delivery_format?: string,
  isNotPresent?:boolean,
  isSubmitted?: boolean,
  isVerified?: boolean,
  isClosed?: boolean,
  videostream_link?: string
  invigilatorInfo?: {
    first_name: string;
    last_name: string;
    contact_email: string;
  },
  response?:{
    id:number,
    created_on: moment.Moment,
    is_resolved?:number,
  },
  upload?:{
    url:string,
    caption: string,
    filePath: string,
  },
  loa_uploaded_on?:moment.Moment,
  instit_name?:string,
  additional_accommodations?:string,
}
export interface IAvailableSession {
  custom_booking_buffer_d?: number;
  isTestDay?: boolean,
  __isStartingSoon?: boolean,
  __dateTimeStartStr?: string,
  __num: number,
  id: number,
  test_session_group_id: number;
  // location
  room: string,
  invigLang:string,
  campusBuilding: string,
  address: string,
  city: string,
  province: string, 
  postalCode: string,
  phoneNumber: string,
  facultyId: number,
  delivery_format?: string,
  // time
  dateTimeStart: moment.Moment,
  closedOn: moment.Moment,
  cancelledOn: moment.Moment,
  // capacity
  capacity: number,
  // restrictions
  isAccessCodeEnabled: boolean,
  accessCode: string,
  isHidden: boolean,
  // assigned invigil
  inivigilatorId: number,
  inivigilatorFirstName: string,
  inivigilatorLastName: string,
  inivigilatorEmailName: string,
  //
  status?: string,
  videostream_link?:string,
  videostream_password?:string,
  // invitation code
  invitationCode: string,
  // bookings
  bookingsCount: number,
  bookings: IBooking[],
  waitlistCount: number,
  waitlist: IBooking[],
  testWindowId: number,
  time_ext_m: number,
  is_paused: boolean,
  is_closed: boolean;
  is_cancelled: boolean;
  is_mobile_tether: boolean;
  is_video_conference: boolean;
  test_window_info: {
    duration_m?: number,
    test_centre_name_slug?: string,
    classroom_name_slug?: string,
    remote_name_slug?: string,
    is_invig_taketest?: number
  }
  testWindowTitle?: any;
  bookingBuffer?:number;
  cancellingBuffer?:number;
}

// DATA
let SAMPLE_INVITATION_CODES = _SAMPLE_INVITATION_CODES;

// UTILITY FUNCTIONS
export const randId = () => randInt(1, 10000000);
export const randInt = (from, to) => Math.floor( from + (to-from)*Math.random() );
export const randArrEntry = <T>(arr:T[]) : T =>  arr[Math.floor(arr.length * Math.random())];
export const randArrEntryProp = <T>(arr:any[], prop:string)  : T => randArrEntry(arr)[prop]
export const randDate = ():string => (new Date().toString());
export const coinFlip = () => (Math.random() > 0.5);
export const generateEntries = <T> (len:number, method:()=>T) : T[] => {
  const arr = [];
  for (let i=0; i<len; i++){
    arr.push( method() );
  }
  return arr;
}
let cheapCount = 0;
export const cheapCounter = (str:string) : string => {
  cheapCount ++;
  if (cheapCount > 1000){
    cheapCount = 1;
  }
  return str +' '+cheapCount;
}
export const shuffle = (array:any[]) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
// data functions
const getInvitCode = () => {
  const i = Math.floor(SAMPLE_INVITATION_CODES.length * Math.random());
  const str = SAMPLE_INVITATION_CODES[i];
  if (SAMPLE_INVITATION_CODES.length === 0){
    SAMPLE_INVITATION_CODES = _SAMPLE_INVITATION_CODES;
  }
  SAMPLE_INVITATION_CODES.splice(i, 1);
  return applySecretKeyMask(str);
}
const randomPastDate = (dayVary?:number) => {
  dayVary = dayVary || randInt(5, 10);
  const time = [randInt(9, 18), 15*randInt(0, 3)].join(':');
  return moment(time, 'H:m').subtract(dayVary, 'days');
}
const dummyStreetNames = [
  'Cherrie Street',
  'Wallnut Avenue',
  'Oake Dr.',
  'Furn St.',
  'Redwould Ave.',
  'Why Toak Rd.',
]
const randStreetName = () => {
  return randArrEntry(dummyStreetNames);
}
const dummyCampusBuildings = [
  'Arts Building',
  'Legacy Building',
  'North Eastern Campus',
  'Green Campus',
  'Center for Educational Excellence',
]
const randCampusBuilding = () => {
  return randArrEntry(dummyCampusBuildings);
}

@Injectable({
  providedIn: 'root'
})
export class DemoDataService {

  public isSimulNewAccount: boolean;
  private accounts:Partial<IAccountInfo>[];
  private sessions:IAvailableSession[];
  private pendingAccommBookings:IBooking[];
  private userInfo:IUserInfo;
  private sessionIdCounter = 100;
  private accountIdCounter = 100;

  constructor(
    private auth:AuthService,
  ) { 
    this.auth.user().subscribe(userInfo => this.userInfo = userInfo);
  }

  getAccounts(){
    if (!this.accounts){
      this.generateNewAccounts();
    }
    return this.accounts;
  }

  overrideAccountData(accounts){
    this.accounts = accounts;
    this.accounts.forEach(account => {
      if (!account.id){
        account.id = this.getNextAccountId();
      }
    })
    // console.log('overrideAccountData', this.accounts)
  }

  public getInvitationCode(){
    return getInvitCode();
  }
 
  
  public getTestSessions(){
    if (!this.sessions){
      this.generateTestSessions();
    }
    this.sortSessions();
    return this.sessions;
  }

  isMeAccommCoord(){
    let isMe = false;
    this.accounts.forEach(account => {
      if (account.isSelf){
        isMe = account.isAccommCoord;
      }
    })
    return isMe;
  }

  getPendingAccommodations(){
    if (!this.pendingAccommBookings){
      this.pendingAccommBookings = this.generateFakeBookings(randInt(4, 12));
    }
    return this.pendingAccommBookings;
  }

  private sortBookingsByDate(bookings:IBooking[]){
    return bookings.sort( (a,b) => {
      const _a = a.timestamp.format('YYYY-MM-DD HH:mm');
      const _b = b.timestamp.format('YYYY-MM-DD HH:mm');
      if (_a == _b){ return 0; }
      if (_a < _b){ return -1; }
      return 1; 
    })
  }

  public sortSessions(){
    this.sessions.forEach(session => {
      session.__dateTimeStartStr = session.dateTimeStart.format('YYYY-MM-DD HH:mm');
    })
    this.sessions  = this.sessions.sort( (a,b) => {
      if (a.__dateTimeStartStr == b.__dateTimeStartStr){ return 0; }
      if (a.__dateTimeStartStr < b.__dateTimeStartStr){ return -1; }
      return 1; 
    })
    this.sessions.forEach( (session, i) => {
      session.__num = i + 1;
    })
  }

  getSessionById(sessionId:number){
    if (!this.sessions){
      this.generateTestSessions();
    }
    let session:IAvailableSession;
    this.sessions.forEach(_session =>{
      if (_session.id === sessionId){
        session = _session;
      }
    })
    return session;
  }

  public cancelSession(sessionId:number){
    let i = -1;
    this.sessions.forEach( (session, sessionIndex) => {
      if (session.id === sessionId){
        i = sessionIndex;
      }
    })
    if (i!== -1){
      this.sessions.splice(i, 1);
    }
  }

  private generateTestSessions(){
    // console.log('generateTestSessions')
    // this.getAccounts();
    // this.sessions = [
    //   this.generateFakeTestSession(true, false, false, false),
    // ]
    // _.times(randInt(2,4), () => {
    //   this.sessions.push(this.generateFakeTestSession(false, coinFlip(), coinFlip(), coinFlip()))
    // })
  }



  private getNextSessionId(){
    return this.sessionIdCounter ++;
  }

  private getNextAccountId(){
    return this.accountIdCounter ++;
  }


  private generateFakeBookings(bookingsCount:number){
    const bookings = [];
    _.times(bookingsCount, () => {
      const identity = randomIdentity();
      bookings.push({
        name: identity.name,
        nameSortable: identity.lastName + ', ' + identity.firstName, 
        email: identity.email,
        oct_id: ''+randInt(100000,999999),
        timestamp: randomPastDate(),
      });
    });
    const bookingsSorted = this.sortBookingsByDate(bookings);
    return bookingsSorted;
  }

  public loadRealTestSessions(sessions:IAvailableSession[]){
    console.log('loadRealTestSessions')
    this.getAccounts();
    this.sessions = sessions.map(session => {
      const invigilator:IAccountInfo = <IAccountInfo> randArrEntry(this.accounts);
      session.inivigilatorId = invigilator.id;
      return session;
    })
  }

  private generateFakeTestSession(isStartingNow:boolean, hasWaitingList:boolean, isHidden:boolean = false, isAccessCodeEnabled:boolean=false){
    // to do assign invigilator
    const capacity = randInt(20, 50);
    let dateTimeStart;
    if (isStartingNow){
      const aFewMinutesAgoTime = [moment().format('H'), 0].join(':');
      dateTimeStart = moment(aFewMinutesAgoTime, 'H:m');
    }
    else{
      dateTimeStart = randomPastDate(-randInt(3, 10));
    }
    let accessCode = '';
    if (isAccessCodeEnabled){
      accessCode = randInt(10000, 1000000).toString(16)
    }
    const invitationCode = this.getInvitationCode();
    let bookingsCount = 0;
    let waitlistCount = 0;
    if (hasWaitingList){
      bookingsCount = capacity;
      waitlistCount = randInt(1,4);
    }
    else{
      bookingsCount = randInt( Math.round(capacity*0.7), capacity-1);
    }
    const bookings:IBooking[] = this.generateFakeBookings(bookingsCount);
    const waitlist:IBooking[] = this.generateFakeBookings(waitlistCount);
    const invigilator:IAccountInfo = <IAccountInfo> randArrEntry(this.accounts);
    invigilator.numSessions ++;

    return {
      __isStartingSoon: isStartingNow,
      __num: 7,
      id: this.getNextSessionId(),
      // location
      room: ''+randInt(100,600),
      campusBuilding: randCampusBuilding(),
      address: randInt(10,200) +' '+ randStreetName(),
      city: 'Toronto',
      province: 'ON', 
      postalCode: 'M1D 7J9',
      phoneNumber: '(647)'+randInt(100,900)+randInt(1000,9900),
      facultyId: 0,
      // time
      dateTimeStart,
      // capacity
      capacity,
      // restrictions
      isAccessCodeEnabled,
      accessCode,
      isHidden,
      // assigned invigil
      inivigilatorId: invigilator.id,
      // invitation code
      invitationCode,
      // bookings
      bookingsCount,
      bookings,
      waitlistCount,
      waitlist,
    }
  }

  getInvigilatorBasicInfo(invigilatorId:number){
    let account:Partial<IAccountInfo>;
    this.accounts.forEach(_account => {
      if (_account.id === invigilatorId){
        account = _account;
      }
    })
    if (account){
      if (account.isInviteUsed){
        return {
          id: account.id,
          name: account.firstName + ' ' + account.lastName,
          email: account.email
        }
      }
      else{
        return {
          id: account.id,
          // name: account.invitationName,
          // email: account.invitationEmail
        }
      }
    }
  }

  private generateNewAccounts(){
    this.accounts = [];
    if (this.userInfo){
      this.accounts.push({
        id: this.userInfo.uid,
        isSelf: true,
        firstName: this.userInfo.firstName,
        lastName: this.userInfo.lastName,
        email: this.userInfo.email,
        phoneNumber: '(705)555-5555',
        isAccommCoord: false,
        numSessions: 0, // temp
        accountCreatedOn: randomPastDate(18), // temp
        accessGrantedOn: randomPastDate(20), // temp
        isInviteUsed: true,
      })
    }
    // _.times(randInt(1,4) , ()=> {
    //   const isUsedInvite = true; // coinFlip();
    //   let identity = randomIdentity();
    //   this.accounts.push({
    //     id: this.getNextAccountId(),
    //     firstName: identity.firstName,
    //     lastName: identity.lastName,
    //     email: identity.email,
    //     phoneNumber: '(705)555-5555',
    //     isAccommCoord: false,
    //     numSessions: 0, //isUsedInvite ? randInt(0,4) : 0, // temp
    //     accountCreatedOn: randomPastDate(18), // temp
    //     invitationCode: getInvitCode(), // temp
    //     invitationName: identity.name,
    //     invitationEmail: identity.email,
    //     accessGrantedOn: randomPastDate(20), // temp
    //     isInviteUsed: isUsedInvite,
    //   });
    // })
  }

}
