import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ScrollService } from '../../core/scroll.service';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
import { LangService } from '../../core/lang.service';
import { DemoAlert, triggerDemoAlert } from '../../core/demo-alerts.service';
import { AuthService, getFrontendDomain } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { regErrorMsgToTransSlug } from '../../api/constants/registration-validation';

interface IRegistrationMeta {
  [key:string]:string
}
export interface ICreateFromInfo {
  collegeApplicationNumber: string, 
  firstName: string,
  lastName: string, 
  email: string,
  password: string,
  meta: IRegistrationMeta,
  langCode? :string
  domain? :string
}

export interface ISlugEntry {
  slug: string,
  caption: string,
  fc?: FormControl,
}
export interface ITeachableEntry extends ISlugEntry {
}

export const processTransData2dList = (lang: LangService, slug:string, isFc:boolean) : ISlugEntry[] =>  {
  const json = lang.tra(slug);
  try {
    const arrs = JSON.parse(json);
    return arrs.map(arr => {
      return {
        slug: arr[0],
        caption: arr[1],
        fc: isFc ? new FormControl(false) : undefined, 
      }
    })
  }
  catch (e) {
    console.error('Unreadable teachable list:', slug, json)
    return [];
  }
}



@Component({
  selector: 'view-tt-create-account',
  templateUrl: './view-tt-create-account.component.html',
  styleUrls: ['./view-tt-create-account.component.scss']
})
export class ViewTtCreateAccountComponent implements OnInit {

  constructor(
    private loginGuard: LoginGuardService, // 
    private auth: AuthService, // 
    private routes: RoutesService,
    public lang: LangService, // 
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private scrollService: ScrollService,
  ) { }

  public breadcrumb = [];
  public isFormValidated:boolean;
  public isFormFailed:boolean;
  public emailUsed:string;
  public formFailReason:string;
  public isFeatureDisabled:boolean = false; // temporary
  public formGroup = new FormGroup({
    collegeApplicationNumber: new FormControl(),
    givenName: new FormControl(),
    preferredName: new FormControl(),
    surname: new FormControl(),
    emailAddress: new FormControl(),
    password: new FormControl(),
    passwordConfirm: new FormControl(),
    requireFr: new FormControl(),
    teacherProgram: new FormControl(),
    applicantType: new FormControl(),
    studyLevel: new FormControl(),
    teacherEdProg: new FormControl(),
    internationalCountry: new FormControl(),
    province: new FormControl(),
    attestation: new FormControl(true),
  })
  public teachables1:ITeachableEntry[] = [];
  public teachables2:ITeachableEntry[] = [];
  public studyLevels:ISlugEntry[] = [];
  public applicantTypes:ISlugEntry[] = [];
  public teachingLevels:ISlugEntry[] = [];
  public teacherEdProgs:ISlugEntry[] = [];
  public countries:ISlugEntry[] = [];
  public provinces:ISlugEntry[] = [];
  public isLoading = true;

  ngOnInit() {
    this.loginGuard.deactivate();
    this.auth.logout();
    this.scrollService.scrollToTop();
    this.breadcrumb = [
      this.breadcrumbsService.APPLICANT_LANDING(),
      this.breadcrumbsService._CURRENT( this.lang.tra('title_create_account'), this.router.url),
    ];
    this.initRouteView();
  }

  initRouteView(){
    this.countries = processTransData2dList(this.lang, 'data_countries', false);
    this.provinces = processTransData2dList(this.lang, 'data_provinces', false);
    this.studyLevels = processTransData2dList(this.lang, 'data_study_levels', false);
    this.applicantTypes = processTransData2dList(this.lang, 'data_appl_types', false);
    this.teachingLevels = processTransData2dList(this.lang, 'data_teach_prog', false);
    this.teacherEdProgs = processTransData2dList(this.lang, 'data_teacher_ed_progs', false);
    this.auth
      .apiGet(this.routes.LANDING_APPLICANT_REGISTRATION, 0, {query: {langCode: this.lang.c()}})
      .then(res => {
        this.isFeatureDisabled = !res.isOpen;
        this.isLoading = false;
        // load teachable from endpoint, not translations' data_teachables_a anymore
        const {teachables1, teachables2} = this.processTeachablesFromApi(res.teachables);
        this.teachables1 = teachables1;
        this.teachables2 = teachables2;
      })
  }

  getAttestationRouterLink(){
    return `/${this.lang.c()}/attestation`;
  }
  
  processTeachablesFromApi(data: any[]): {teachables1: ITeachableEntry[], teachables2: ITeachableEntry[] | any[]} {
    const teachables1: ITeachableEntry[] = [], teachables2: ITeachableEntry[] = [];
    data.map(teachable => {
      try {
        const entry = {
          slug: teachable.slug,
          caption: teachable.caption,
          fc: new FormControl(false)
        }
        if (teachable.slug.startsWith('aa')){
          teachables1.push(entry);
        }
        if (teachable.slug.startsWith('ab')){
          teachables2.push(entry);
        }
      }
      catch (e) {
        console.error('Unreadable teachable list:', data, teachable)
      }
    })
    return {teachables1, teachables2};
  }

  isPasswordPolicyShowing:boolean;
  togglePasswordPolicy(){
    this.isPasswordPolicyShowing = !this.isPasswordPolicyShowing;
  }

  isSubmitting:boolean;
  submitForm(){

    this.isSubmitting = true;
    this.isFormFailed = false;
    this.createAccount()
      .then(res => {
        this.isFormValidated = true;
        this.scrollService.scrollToTop();
        this.isSubmitting = false;
      })
      .catch(err => {
        this.isFormFailed = true;
        this.isSubmitting = false;
        this.formFailReason = regErrorMsgToTransSlug(err.message);
        this.refreshFailReason();
      })

  }

  checkApplicantTypeValue = (val:string) => this.formGroup.controls.applicantType.value === val
  isOntarioApplicant = () => this.checkApplicantTypeValue('oa');
  isLabourMobilityApplicant = () => this.checkApplicantTypeValue('lma');
  isCurrentMember = () => this.checkApplicantTypeValue('ccm');
  isInternationalApplicant = () => this.checkApplicantTypeValue('iet');
  
  isRefreshingFailReason:boolean;
  private refreshFailReason(){
    this.isRefreshingFailReason = true;
    setTimeout(() => this.isRefreshingFailReason = false, 500)
  }

  private gatherTeachables(){
    const teachablesList:string[] = [];
    let numSchedB = 0;
    const trackTeachables = (arr:ITeachableEntry[], isSchedB?:boolean) => {
      arr.forEach(teachable => {
        if (teachable.fc.value){
          teachablesList.push(teachable.slug);
          if (isSchedB){
            numSchedB ++;
          }
        }
      });
    }
    trackTeachables(this.teachables1, false);
    trackTeachables(this.teachables2, true);
    return {teachables: teachablesList, numSchedB};
  }

  private createAccount(){

    const meta:IRegistrationMeta = {};

    const collegeApplicationNumber = this.formGroup.controls.collegeApplicationNumber.value;
    if (!collegeApplicationNumber){ return Promise.reject({ message: 'MISSING_OCT_ID' }) }

    const firstName = this.formGroup.controls.givenName.value;
    if (!firstName){ return Promise.reject({ message: 'MISSING_FIRSTNAME' }) }

    const lastName = this.formGroup.controls.surname.value;
    if (!lastName){ return Promise.reject({ message: 'MISSING_LASTNAME' }) }

    const preferredName = this.formGroup.controls.preferredName.value;
    if(preferredName){ 
      meta['preferred_name'] = preferredName;
    }
    
    const email = this.formGroup.controls.emailAddress.value;
    if (!email){ return Promise.reject({ message: 'MISSING_EMAIL' }) }
    if (!this.validateEmail(email)){ return Promise.reject({ message: 'EMAIL_MALFORMED' }) }

    const password = this.formGroup.controls.password.value;
    const passwordConfirm = this.formGroup.controls.passwordConfirm.value;
    if (!password){ return Promise.reject({ message: 'MISSING_PASSWORD' }) }
    if (password !== passwordConfirm){ return Promise.reject({ message: 'PASS_MATCH' }) }

    const isDropDownUnset = (val:string) => {
      if (!val || val === '---'){
        return true;
      }
    }

    const applicantType = this.formGroup.controls.applicantType.value;
    if (isDropDownUnset(applicantType)){ return Promise.reject({ message: 'MISSING_APPLICANTTYPE' }) }
    meta['applicant_type'] = applicantType;

    if (this.isOntarioApplicant()){
      const faculty = this.formGroup.controls.teacherEdProg.value;
      if (isDropDownUnset(faculty)){ return Promise.reject({ message: 'MISSING_FACULTY' }) }
 
      const studyLevel = this.formGroup.controls.studyLevel.value;
      if (isDropDownUnset(studyLevel)){ return Promise.reject({ message: 'MISSING_STUDYLEVEL' }) }

      meta['applicant_faculty'] = faculty;
      meta['applicant_study_year'] = studyLevel;
    }

    if (this.isCurrentMember() || this.isOntarioApplicant()){
      const teacherProgram = this.formGroup.controls.teacherProgram.value;
      if (isDropDownUnset(teacherProgram)){ return Promise.reject({ message: 'MISSING_TEACHERPROGRAM' }) }

      const {teachables, numSchedB} = this.gatherTeachables();

      if (teacherProgram == 'JI' && teachables.length < 1){ return Promise.reject({ message: 'MISSING_TEACHABLES_1' }) }
      if (teacherProgram == 'IS' && (teachables.length < 2 && numSchedB < 1)){ return Promise.reject({ message: 'MISSING_TEACHABLES_2' }) }
      
      meta['applicant_teach_prog'] = teacherProgram;
      meta['applicant_teachables'] = JSON.stringify(teachables) 
    }

    if (this.isLabourMobilityApplicant()){
      const province = this.formGroup.controls.province.value;
      if (isDropDownUnset(province)){ return Promise.reject({ message: 'MISSING_PROVINCE' }) }
// 
      meta['applicant_lma_province'] = province;
    }

    if (this.isInternationalApplicant()){
      const internationalCountry = this.formGroup.controls.internationalCountry.value;
      if (isDropDownUnset(internationalCountry)){ return Promise.reject({ message: 'MISSING_TEACHERPROGRAM' }) }
      
      meta['applicant_int_country'] = internationalCountry;
    }
    
    const attestation = this.formGroup.controls.attestation.value;
    if (!attestation){ return Promise.reject({ message: 'MISSING_ATTEST' }) }
    
    this.emailUsed = email;

    const req:ICreateFromInfo = {
      collegeApplicationNumber: collegeApplicationNumber.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      password: password.trim(),
      meta,
      langCode : this.lang.c(),
      domain: getFrontendDomain(),
    }

    return this.auth.apiCreate( this.routes.AUTH_TEST_TAKER, req )

  }

  private validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}