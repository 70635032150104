<div class="footer dont-print" [class.no-pointer-events]="isUsingSebSoftware()">
  <div *ngIf="hasLinks && getFooter()" class="footer-links">
    <div class="footer-column" *ngFor="let footerColumn of getFooter()">
      <div
        *ngFor="let footer of footerColumn"
        [ngSwitch]="getFooterType(footer)"
      >
        <a *ngSwitchCase="FooterType.ROUTE" routerLink="/{{clang()}}{{footer.route}}"><tra [slug]="footer.caption"></tra></a>
        <a *ngSwitchCase="FooterType.LINK" target="_blank" href="{{lang.tra(footer.link)}}"><tra [slug]="footer.caption"></tra></a>
        <div *ngSwitchCase="FooterType.IMAGE_LINKS" class="social-media-icons">
          <div *ngFor="let imageLink of footer.imgLinks">
            <img *ngIf = "imageLink.urlTranslationSlug.length === 0" [src]="imageLink.src" [attr.alt]="imageLink.urlTranslationSlug + '_icon'">
            <a *ngIf = "imageLink.urlTranslationSlug.length !== 0" [href]="lang.tra(imageLink.urlTranslationSlug)" target="_blank" [attr.aria-label]="'Visit ' + imageLink.urlTranslationSlug"><img [src]="imageLink.src" [attr.alt]="imageLink.urlTranslationSlug + '_icon'"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-lower">
      <div class="copyright" style="text-align: left;">
        <span *ngIf="!isBilingual">
          <tra-md [slug]="getCopyright()"></tra-md>
        </span>
        <span *ngIf="isBilingual">
          <tra-md [slug]="getBilingualCopyright()" [isCondensed]="true"></tra-md>
        </span>
      </div>
      <div>
        <button *ngIf="isAppealShown && allowAppeals.sysAllow" [routerLink]="renderAppealLink()" class="footer-comment-link">
          <tra slug="btn_file_an_appeal"></tra>
        </button>
        <span *ngIf="isAppealShown && allowAppeals.sysAllow" style="color: #999;margin:0em 0.5em;">•</span>
        <button *ngIf="hasComments" (click)="supportReqActivate()" class="footer-comment-link technical-support">
          <tra slug="title_feedback"></tra>
        </button>
      </div>
  </div>
</div>