import { Component, OnInit, Input } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { SEBService } from '../../ui-testtaker/seb.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { FooterType, IFooterDef } from '../../domain/contexts/common';
import { AccountType } from '../../constants/account-types';
import { AllowAppealsService, EAppealType } from 'src/app/ui-testtaker/allow-appeals.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  @Input() hasLinks:boolean = true;
  @Input() hasComments:boolean = true;
  @Input() isAppealShown:boolean = false;
  @Input() appealSessionId: number;
  @Input() appealAttemptId: number;
  @Input() isBilingual: boolean = false;

  subscription = new Subscription();
  public usingSEB = false;
  FooterType = FooterType

  constructor(
    public lang: LangService,
    private whitelabel: WhitelabelService,
    private loginGuard: LoginGuardService,
    private seb: SEBService,
    public allowAppeals: AllowAppealsService
  ) { }
  
  ngOnInit() {
    this.subscription.add(
        this.seb.getSub().subscribe(isSEB => {
          console.log(`footer isSEB: ${isSEB}`)
          this.usingSEB = isSEB
        })
    )
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  
  supportReqActivate(){
    this.loginGuard.supportReqActivate()  
  }

  clang(){
    return this.lang.getCurrentLanguage();
  }

  getFooter(){
    return this.whitelabel.getFooter();
  }

  getFooterType(footer:IFooterDef){
    return footer.type
  }

  getBilingualCopyright(){
    return `${this.lang.tra("linked_title_copyright", "en")} <br> ${this.lang.tra("linked_title_copyright", "fr")}`
  }

  getCopyright(){
    return this.whitelabel.getSiteText('copyright');
  }
  isUsingSebSoftware(){
    return navigator.userAgent.match(/SEB/i) ? true: false
  }
  renderAppealLink(){
    if (this.appealAttemptId){
      return `/${this.lang.c()}/${AccountType.TEST_TAKER}/file-appeal/attempt/${this.appealAttemptId}`
    }
    else if (this.appealSessionId){
      return `/${this.lang.c()}/${AccountType.TEST_TAKER}/file-appeal/session/${this.appealSessionId}`
    }
    else {
      return `/${this.lang.c()}/${AccountType.TEST_TAKER}/file-appeal`
    }
  }
}
