import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '../api/auth.service';
import { LangService } from '../core/lang.service';

const DEFAULT_SEB_ERROR = "missing_seb_header";
const DEFAULT_SEB_ERROR_MESSAGE = "msg_error_open_test";
@Injectable({
  providedIn: 'root'
})
export class SEBService {
  private sub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private sebError: string = DEFAULT_SEB_ERROR_MESSAGE;

  constructor(
      private auth: AuthService,
      private lang: LangService,
  ) {}

  public getSub() {
    return this.sub;  
  }

  public getError(){
    return this.sebError;
  }

  public getSEBFaileSafeCheck(){
    return (window as any).SafeExamBrowser?.security?.browserExamKey != undefined ? 1: 0;
  }
  

  public check(sessionId: number) {
    this.auth.apiFind('public/test-taker/invigilation/attempt-checklist-seb', {query:{test_session_id: sessionId, sebFailSafe: this.getSEBFaileSafeCheck(), validatedSEBConfig: this.auth.getSEBValidatorStatus()}})
    .then(res => {
      this.sub.next(true);
    }).catch(e => {
      const slug = e.message;
      const errorMessageCheck = this.lang.tra(slug);
      if(errorMessageCheck && e.message != DEFAULT_SEB_ERROR){
        this.sebError = errorMessageCheck;
      }
      this.sub.next(false);
    });
  }
}
