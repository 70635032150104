import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import {BehaviorSubject, Subject} from 'rxjs';
import {AccountType} from '../constants/account-types';
import {API_ADDRESS_PROD, API_ADDRESS_QC1, API_ADDRESS_QC3, API_ADDRESS_XQC1, API_ADDRESS_LOCAL} from './api-addr/l';
import {HttpClient} from '@angular/common/http';
import { WhitelabelService } from '../domain/whitelabel.service';

const auth = require('@feathersjs/authentication-client');

export const F_ERR_MSG__FAILED_TO_FETCH = 'Failed to fetch'; // if feather is unable to connect to the server
export const F_ERR_MSG__INVALID_LOGIN = 'Invalid login'; 
export const F_ERR_MSG__INVALID_EMAIL = 'Invalid Email'; 
export const F_ERR_MSG__NO_PASSWORD = 'No Password'; 
export const F_ERR_MSG__REAUTH_NO_TOKEN = 'No accessToken found in storage'; 
export const F_ERR_MSG__RELOGIN = 'jwt expired'; 
export const SEBValidatorItem = "validatedSEBConfig";

const TOKEN_REFRESH_INTERVAL = 10*60*1000;

interface IAuthRes {
  accessToken: string,
  user?: IUserInfoCore, 
  authentication?: {
    payload: IUserInfoCore
  }
}
interface IApiFindQuery {
  query?: {
    $limit?: number,
    [key: string]: any;
  }
  [key: string]: any;
}
interface IUploadResponse { 
  success: boolean, 
  filePath?: string,
  url?: string,
}
export interface IUserInfoCore {
  email: string,
  uid: number,
  accountType: AccountType,
  accountId: number,
  accountInfo: {
    institutionId? :number // test admins only
  },
  // roles?: string[],
  firstName: string,
  lastName: string,
}

export interface IUserInfo extends IUserInfoCore{
  accessToken: string,
}

export const DB_TIMEZONE = 'Z';

export const getFrontendDomain = () => {
  return window.location.origin+'/';
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private api = <any>feathers();
  private restClient = <any>rest(this.whitelabel.getApiAddress());
  private _user:IUserInfo;
  private userSub:BehaviorSubject<IUserInfo> = new BehaviorSubject(null);
  private reauthCompletedSub:BehaviorSubject<boolean> = new BehaviorSubject(false);
  private apiNetFail:BehaviorSubject<boolean> = new BehaviorSubject(false); // gets overridden upon registration
  private apiAuthExpire:Subject<boolean> = new Subject(); // if you want to use BehaviorSubject, need to be diligent about setting this to false whenever the user is re-authenticated
  public isLoggingIn:boolean;
  private isLoggedIn:boolean = false;
  private sebValidatorStatus:number;

  constructor(
    private router: Router,
    private httpClient:HttpClient,
    private whitelabel: WhitelabelService,
  ) { 
    this.api.configure(this.restClient.fetch(window['fetch']));
    this.api.configure(auth({ storage: window['localStorage'] }));
    this.reauth();
    setInterval(this.refreshRefreshToken, TOKEN_REFRESH_INTERVAL);
    // this.clearLocalSession()
    if(!this.sebValidatorStatus){
      this.sebValidatorStatus = this.getItemWithExpiration(SEBValidatorItem);
    }
  }

  public user(){
    return this.userSub;
  }

  public getUid() {
    if(!this.user() || !this.user().getValue()) {
      return -1;
    }
    
    return this.user().getValue().uid;
  }

  getDashboardRoute(lang:string){
    if (this._user && this._user.accountType){
      return `/${lang}/${this._user.accountType}/dashboard`
    }
    return `/`
  }

  getTimezone(){
    return 'America/Toronto';
  }

  public getDomain(){
    var domain1=window.location.origin;
    domain1 = domain1.replace(/^https?:\/\//,'')
    return domain1;
  }

  public userIsStudent() {
    return this._user?.accountType === AccountType.TEST_TAKER;
  }

  public getReauthCompletedSub(){
    return this.reauthCompletedSub;
  }

  getApiAuthExpire(){
    this.apiAuthExpire;
  }

  public isQcBranch(){
    return (this.whitelabel.getApiAddress() === API_ADDRESS_QC1)
  }

  public registerNoApiNetSub(apiNetFail:BehaviorSubject<boolean>){
    apiNetFail.next(this.apiNetFail.getValue()); // usually this first one will only be around for a fraction of a second. in any case, it is private so nothing outside of this calss should be subscribing to it becaus it will be wiped out
    this.apiNetFail = apiNetFail;
  }
  private clearNetworkError = (res:any) => {
    this.apiNetFail.next(false);
    return res;
  }
  private catchNetworkError = (e) => {
    // console.log('catch net error', e.message)
    if (e.code === 500){
      this.apiNetFail.next(true);
    }
    else if (e.message === F_ERR_MSG__RELOGIN){
      this.apiAuthExpire.next(true); // not being used at the moment
      this.clearUser();
      this.isLoggedIn = false;
    }
    else if (e.message === F_ERR_MSG__FAILED_TO_FETCH){
      this.apiNetFail.next(true);
    }
    else if (e.message === F_ERR_MSG__FAILED_TO_FETCH){
      this.apiNetFail.next(true);
    }
    throw e;
  }

  private clearLocalSession(){
    
    this.api.authentication.removeAccessToken();
  }

  private refreshUserInfo = (res:IAuthRes, isSilent:boolean=false) => {
    this._user = {
      ... res.user || res.authentication.payload,
      accessToken:  res.accessToken,
    }
    if (!isSilent){
      this.userSub.next(this._user)
    }

    this.storeRefreshInfo(res);
    return res;
  }

  private getToken = (res: IAuthRes) => {
    return res.accessToken;
  }

  getDisplayName(){
    if (this._user){
      return this._user.firstName+' '+this._user.lastName
    }
    return 'Not Logged In';
  }
  

  uploadFile(file: File | Blob, filename:string, purpose:string='_general', isPermaLink:boolean=false) : Promise<IUploadResponse>{
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';
    formData.append('form_upload', file, filename);
    formData.append('uid', ''+uid);
    formData.append('purpose', purpose);
    formData.append('isPermaLink', isPermaLink ? '1' : '0');
    formData.append('jwt', jwt);
    return this.httpClient
      .post(this.whitelabel.getApiAddress()+'/upload', formData)
      .toPromise()
      .then(res => <IUploadResponse>res );
  }

  public refreshRefreshToken = () : Promise<any> => {
    //console.log("calling refresh token")
    if (!this.isLoggedIn) return null;
    return this.api
    .authenticate({
      strategy: 'local',
      action: "refresh",
      refresh_token: this.getRefreshToken()
    })
    .then((res)=> {
      this.storeRefreshInfo(res);
      this.isLoggedIn = true;
    })
    .catch(this.catchNetworkError)
  }
  
  private getRefreshToken(){
    return localStorage.getItem('refresh-token')
  }

  private storeRefreshInfo(res){
    if (res && res.refreshToken) {
      localStorage.setItem('refresh-token',res.refreshToken);
    }
  }

  clearUser = (e?:any) => {
    this.userSub.next(null);
    this._user = null;
  }

  public reauth() : Promise<any> {
    return this.api
      .reAuthenticate()
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then( res => { this.reauthCompletedSub.next(true); return res })
      .then(() => {
        this.isLoggedIn = true
      })
      .catch( e => { this.reauthCompletedSub.next(true); return e })
      .catch(this.catchNetworkError)
      .catch( e => { if (e.message !== F_ERR_MSG__REAUTH_NO_TOKEN){ throw e;} }) // no token is not really an error since we are not checking for it in the first place
  }

  public login(email:string, password:string) : Promise<any> {
    this.isLoggingIn = true;
    return this.api
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => this.isLoggedIn = true)
      .then(()=> this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }
  public getJWT(email: string, password: string): Promise<any> {
    return this.api
        .authenticate({
          strategy: 'local',
          email,
          password,
        })
        .then(this.getToken)
        .catch( err => { this.isLoggingIn = false; throw err; })
  }

  dataFilePath(path:string, options:{[key:string]: string | number}){
    let optionStr = '';
    if(options){
      Object.keys(options).forEach(param => optionStr+='&'+param+'='+options[param] );
    }
    return this.whitelabel.getApiAddress()+'/data-frame.xlsx?uid='+this._user?.uid+'&path='+path+optionStr+'&jwt='+this._user?.accessToken;
  }

  public logout() : Promise<any> {
    return this.api
      .logout()
      .then(this.clearNetworkError)
      .then(this.clearUser())
      .then(() => this.isLoggedIn = false)
      .catch(e => { this.clearLocalSession(); throw e; })
      .catch(this.catchNetworkError)
  }

  public apiGet(route:string, id:string | number,  params?:any) : Promise<any> {
    return this.api
      .service(route)
      .get(id, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  public apiFind(route:string, params?:any) : Promise<any> {
    return this.api
      .service(route)
      .find(params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }
  public apiCreate(route:string, data:any, params?:any) : Promise<any> {
    return this.api
      .service(route)
      .create(data, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }
  public apiPatch(route:string, id:string | number, data:any, params?:any) : Promise<any> {
    // console.log('apiPatch', id, data)
    return this.api
      .service(route)
      .patch(id, data, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }
  public apiRemove(route:string, id:string | number, params?:any) : Promise<any> {
    return this.api
      .service(route)
      .remove(id, params)
      .then(this.clearNetworkError)
      .catch(this.catchNetworkError)
  }

  jsonToExcel(records: any[], fileName) {
    const formData: FormData = new FormData();
    const uid = this._user?.uid || -1;
    const jwt = this._user?.accessToken || '';

    formData.append('uid', ''+uid);
    formData.append('jwt', jwt);
    const jsonRecords = JSON.stringify(records);
    const recordsBlob = new Blob([jsonRecords], {type: 'application/json'});
    formData.append('form_upload', recordsBlob);
    formData.append('fileName', fileName)
    const path = this.whitelabel.getApiAddress()+'/upload-json-as-xlsx';
    return this.httpClient
      .post(path, formData)
      .toPromise();
  }

  public loginAlias(id:string, secret:string) : Promise<any> {
    this.isLoggingIn = true;
    return this.api
      .authenticate({
        strategy: 'alias',
        id,
        secret
      })
      .then(this.clearNetworkError)
      .then(this.refreshUserInfo)
      .then(() => this.isLoggedIn = true)
      .then(() => this.isLoggingIn = false)
      .catch(this.catchNetworkError)
      .catch( err => { this.isLoggingIn = false; throw err; })
  }

  /**
   * This function is update so that not only does it set the SEB validator to true 
   * as a local variable and stores it in local storage for 10 hours. 
   * With that we minimize the chances of losing track of SEB
   * @param sebStatus 1 for true and 0 for false 
   */
  public setSEBStatus(sebStatus: number) {
    this.sebValidatorStatus = sebStatus;
    const tenHours = 10 * 60 * 60 * 1000; // 10 hours in milliseconds
    const expirationTime = new Date().getTime() + tenHours; //10 hours from now
    const itemToStore = {
      value: sebStatus,
      expiration: expirationTime
    };
    localStorage.setItem(SEBValidatorItem, JSON.stringify(itemToStore));
  }

  /**
   * This function returns the status of wheather the SEB config in-use has the validated param or not. 
   *  And if it doesn't find the status in the sebValidatorStatus it checks the local storage for it.
   * @returns validated SEB status
   */
  public getSEBValidatorStatus() {
    return this.sebValidatorStatus ? this.sebValidatorStatus : 0;
  }

  /**
   * This function checks the local storage and confirms that 
   * the local storage item is only active for as long as we need it
   * If the function gets triggered after the item is supposed to expire, 
   * it removes it from the local storage and returns null
   * @param key the key of the local storage item'
   * @returns the value of the localstorage item if it is not expired
   */
  private getItemWithExpiration(key: string) {
    const item = localStorage.getItem(key);
    if (item) {
      const parsedItem = JSON.parse(item);
      const currentTime = new Date().getTime();
      if (currentTime < parsedItem.expiration) {
        return parsedItem.value;
      } else {
        // The item has expired, so remove it
        localStorage.removeItem(key);
      }
    }
    return null;
  }
  
  /**
   * Get user's account type
   * @returns the account type of the user
   */
  myAccountType() {
    if(this._user) {
      return this._user.accountType;
    }
    else{
      return '';
    }
  }
  
  /**
   * Function to force-logout user
   * @param lang 
   * @param accountType 
   */
  forceLogout(lang?: string, accountType?: string) {
    this.logout()
      .then(() => {
        this.router.navigate([`/${lang}/${accountType}/login`]);
      });
  }

}
