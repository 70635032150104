import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LangService } from '../../core/lang.service';
import {TextToSpeechService} from "../text-to-speech.service";

const BaseWidth = 230;
@Component({
  selector: 'formula-sheet',
  templateUrl: './formula-sheet.component.html',
  styleUrls: ['./formula-sheet.component.scss']
})
export class FormulaSheetComponent implements OnInit {

  @Input() scale: number
  constructor(
    public lang: LangService,
    private textToSpeech:TextToSpeechService,
  ) { }

  ngOnInit() {
  }

  isAudioPlayerShowing(){
    return this.textToSpeech.isActive;
  }

  getAudioUrl() {
    return this.lang.tra('mpt_formula_sheet_voiceover');
  }
  
  getShapesAudioUrl() {
    return this.lang.tra('mpt_formula_sheet_shapes_voiceover');
  }

  getSolidsAudioUrl() {
    return this.lang.tra('mpt_formula_sheet_solids_voiceover');
  }

  getPage1Element(paeg_slug){
    return {url: this.lang.tra(paeg_slug), scale: this.scale * BaseWidth, unlockMaxWidth: true}
  }
}
