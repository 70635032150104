import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { IContentElementImage } from '../models';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
@Component({
  selector: 'render-image',
  templateUrl: './render-image.component.html',
  styleUrls: ['./render-image.component.scss']
})
export class RenderImageComponent implements OnInit, DoCheck {

  @Input() element:IContentElementImage;

  urlRaw:string;
  url:SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.updateUrl();
  }

  ngDoCheck() {
    this.updateUrl();
  }

  updateUrl(){
    const url = this.element.url || this.element?.images?.default.image?.url;
    if (this.urlRaw !== url){
      this.urlRaw = url;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRaw);
    }
  }

  getImageUrl(){
    return this.url;
  }

}
