<div *ngIf="element.url" class="mouse-over">
  <!-- <video controls crossorigin="anonymous">
    <source [src]="getVideoUrl()" [type]="element.fileType">
  </video> -->
  <!-- <plyr  plyrTitle="" 
        [plyrPlaysInline]="false" 
        [plyrOptions]="plyrOptions"
        [plyrSources]="getVideoSources()" 
        [plyrTracks]="getSubtitleTracks()" 
        plyrCrossOrigin="anonymous"
  ></plyr> -->
    <!-- <div plyr plyrTitle="" [plyrPlaysInline]="false" [plyrCrossOrigin]="true"
   [plyrSources]="getVideoSources()" [plyrTracks]="getSubtitleTracks()" (plyrInit)="player = $event"
  (plyrPlay)="played($event)"></div> -->
  <div>
    <video 
      #videoPlayer
      [id]="'video'+element.entryId" 
      [autoplay]="hasStarted()" 
      (play)="playing()" 
      (timeupdate)="onTimeUpdate()" 
      (ended)="ended()" 
      (pause)="notPlaying()" 
      [controls]="!isLimitted || element.enableVideoControls" 
      (seeked)="onSeeking()"
      [width]="640 * getZoom()" 
      [height]="480 * getZoom()" 
      controlsList="nodownload nofullscreen" 
      (canplay)="canStartPlaying()"
      (loadedmetadata)="loadedMetaData()"
      disablePictureInPicture
      preload="auto"
      crossorigin="anonymous"
      [class.is-hide-timeline]="!isLimitted"
      [class.is-hide-play-button]="!isLimitted"
    >
      <source *ngFor="let video of videoSourceUrls" [type]="video.type" [src]="video.src">
      <track *ngIf="allowSubtitles"
             default
             kind="subtitles"
             [srclang]="getSubtitleTracks()[0].srclang"
             [src]="getSubtitleTracks()[0].src"
             type="text/vtt"
             [label]="getSubtitleTracks()[0].label">
    </video> 
  </div>
</div>

