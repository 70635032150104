import * as moment from 'moment-timezone';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { TimezoneService } from '../../core/timezone.service';
import { AuthService } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { MyApplAccountService } from '../my-appl-account.service';
import { BreadcrumbsService, IBreadcrumbRoute } from '../../core/breadcrumbs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountType } from '../../constants/account-types';
import { ApiFailCode } from '../../api/constants/access-controls';
import { IQuestionConfig } from '../../ui-item-maker/item-set-editor/models';
import { IRoutingRule } from '../../ui-item-maker/item-set-editor/models/assessment-framework';
import { MptConnectionService } from '../mpt-connection.service';
import { IReport } from '../view-tt-report/view-tt-report.component';
import { SEBService } from '../seb.service';

enum WarningModes {
  INVALID_SEB = 'INVALID_SEB',
  ACCEPT_ATTEST = 'ACCEPT_ATTEST',
  SELECT_LANG = 'SELECT_LANG',
  NOT_BOOKED_APPL = 'NOT_BOOKED_APPL',
  NO_QUESTIONS_FOUND = 'NO_QUESTIONS_FOUND',
  UNKNOWN = 'UNKNOWN',
  INVALID_SEB_BYOD = 'INVALID_SEB_BYOD'
}

export interface ITestAttemptRes {
  lang: string;
  question_index: number;
  section_index: number;
  attempt_key: string;
  attemptId: number;
  testDesign: ITestDesignPayload;
  questions: any[];
  questionStates: any;
  startedOn:any;
  date_time_start: any; /// date
  is_issue_reporting_enabled: number;
  time_ext_m: number;
  test_window_time: number;
  sections_meta:any;
  testFormId: number;
}

export interface ITestDesignPayload {
  label?: string;
  sections: ISectionDef[];
  panelModules?: IPanelModuleDef[];
  panelRouting?: {[key:string]: Array<IRoutingRule>};
  isPanelRoutingByNumCorrect?: boolean;
  questionDb?: {[key: number]: IQuestionConfig};
  testletIds?: any[];
  helpPageId__cache?:number,
  __meta?: ITestMeta; // likely deprecated... from sample questions
}

export interface IQuestionSetDef {
  questions: number[];

  preamble?: number;
  caption?: string;  // likely deprecated... from sample questions

  hasCalculator?: boolean;
  hasFormulas?: boolean;
  isShuffleDisabled?: boolean;
  isTimeLimit?: boolean;
  isConditional?: boolean;
  timeLimitMinutes?: number;
  conditionOnItem?: string;
  conditionOnOption?: string;
}

export interface ISectionDef extends IQuestionSetDef {
  sectionId?:number,
  __meta?: ISectionMeta;  // likely deprecated... from sample questions
}

export interface IPanelModuleDef extends IQuestionSetDef {
  moduleId: number,
}

export interface ITestMeta {
  qs?: number; // how many questions are in the test
}
export interface ISectionMeta {
  qs?: number; // how many questions are in the current section
  qsPrec?: number; // how many questions appear
  markLoc?: string; // % value indicating where the marker should be placed
}

export interface IDur {
  hours: number,
  minutes: number
}

export const renderDur = (durObj: IDur, lang: LangService) => {
  return `${durObj.hours} ${lang.tra('txt_hours')} ${durObj.minutes} ${lang.tra('txt_minutes')}`
} 


@Component({
  selector: 'view-tt-test-runner',
  templateUrl: './view-tt-test-runner.component.html',
  styleUrls: ['./view-tt-test-runner.component.scss']
})
export class ViewTtTestRunnerComponent implements OnInit, OnDestroy {
  confirmReportData: Object = {};
  regularTimeRemaining: IDur;
  extraTimeRemaining: string;
  isNotYetOpen: boolean;
  timeTillOpen: IDur;
  dateTimeStart: moment.Moment;//the official start time of testattempt
  testAttemptInfo: ITestAttemptRes;
  testTakerName: string;
  attemptKey: string;
  isIssueReportingEnabled: boolean;
  unknownWarningErrorMsg: string;
  subscription = new Subscription();
  timeSpentSinceStart: number;
  attemptStartedOn : moment.Moment; //when the test-taker actually starts the testattempt
  report: IReport;
  reportError: boolean;
  reportErrorMsg: any;

  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private route: ActivatedRoute,
    private loginGuard: LoginGuardService,
    public my: MyApplAccountService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    public lang: LangService,
    public timezone: TimezoneService,
    private seb: SEBService,
    private mptConnection: MptConnectionService // needed to call constructor
  ) { }

  public breadcrumb: IBreadcrumbRoute[] = [];
  public sessionId: number;
  public instit_group_id: number;
  public institution: number;
  public  format: string;
  public testDesign: ITestDesignPayload;
  public questionSrcDb;
  public testLang: string;
  public isLoadingAttempt: boolean;
  public WarningModes = WarningModes;
  public isLoaded: boolean;
  public time_ext_m = 0;
  public test_window_time = 0;

  private isInited: boolean;
  private routeSub: Subscription;

  public currentWarning = null;

  renderDur = renderDur;

  ngOnInit() {
    this.loginGuard.activate();
    this.breadcrumb = [
      this.breadcrumbsService.APPLICANT_LANDING(),
      this.breadcrumbsService.APPLICANT_DASHBOARD(),
      this.breadcrumbsService._CURRENT( this.lang.tra('booknow_btn'), this.router.url),
    ];
    this.routeSub = this.route.params.subscribe(routeParams => {
      this.sessionId = parseInt(routeParams['sessionId']);
      this.initRouteView();
    });
   }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initRouteView() {
    this.subscription.add(
      this.my.sub().subscribe(info => {
        if (info) {
          this.isInited = true;
          this.loadAttempt();
        }
      })
    );
    const processSocketReconnection = () => this.mptConnection.disconnect();
    setInterval(processSocketReconnection, 3300000);
  }

  /* acceptAttestation(){
    const test_session_id = this.getTestSessionId();
    this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_ATTEST,
      { test_session_id }
    )
    .then(()=>{
      this.loadAttempt();
    })
  } */
  async acceptAttestation(){
    const test_session_id = this.getTestSessionId();
    const testAttemptCreated =  await this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_ATTEST,
      { test_session_id }
    )
    this.loadAttempt()
    
  }

  selectTestLang(lang: string) {
    const test_session_id = this.getTestSessionId();
    this.loginGuard.confirmationReqActivate({
      caption: this.lang.tra('txt_confirm_lang_test_select', lang),
      btnProceedCaption: this.lang.tra('lbl_yes', lang),
      btnCancelCaption: this.lang.tra('btn_cancel', lang),
      confirm: () => {
        this.auth.apiCreate(
          this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_LANG,
          { test_session_id, lang }
        )
        .then(()=>{
          this.lang.loadInLang(lang); //Update the route parameters with new lang and load in the new test attempt
        })
      }
    });
  }

  getTestSessionId(){
    return parseInt('' + this.sessionId);
  }

  loadAttempt() {
    this.isLoadingAttempt = true;
    const test_session_id: number = this.getTestSessionId();
    const sebFailSafe: number = this.seb.getSEBFaileSafeCheck();
    const validatedSEBConfig:number = this.auth.getSEBValidatorStatus();
    return this.auth
      .apiFind(
        this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT,
        { query: {test_session_id, sebFailSafe, validatedSEBConfig} }
      )
      .then((res: ITestAttemptRes[]) => {
        const testAttemptInfo = res[0];
        this.testAttemptInfo = testAttemptInfo;
        const testDesign = testAttemptInfo.testDesign;
        this.testDesign = {sections: testDesign.sections, helpPageId__cache:testDesign.helpPageId__cache},

        this.testLang = testAttemptInfo.lang;
        this.dateTimeStart = this.timezone.moment(testAttemptInfo.date_time_start);
        this.attemptStartedOn = this.timezone.moment(testAttemptInfo.startedOn);
        this.testTakerName = this.my.getDisplayName();
        this.attemptKey = testAttemptInfo.attempt_key;
        this.isIssueReportingEnabled = testAttemptInfo.is_issue_reporting_enabled === 1;
        this.questionSrcDb = new Map();
        this.time_ext_m = testAttemptInfo.time_ext_m;
        this.test_window_time = testAttemptInfo.test_window_time;

          this.updateTimeRemaining();
          setInterval(this.updateTimeRemaining, 1000);
          //set values for confirmReportData
          this.confirmReportData["prevSessionMeta"] = testAttemptInfo.sections_meta
          this.confirmReportData["attemptId"] = testAttemptInfo.attemptId;
          this.confirmReportData["dateTimeStart"] = this.dateTimeStart;
          this.confirmReportData["attemptStartedOn"] = this.attemptStartedOn;
          this.confirmReportData['attemptKey'] = this.attemptKey;

        this.auth.apiFind(this.routes.TEST_TAKER_TEST_SESSIONS_BOOKING, {query: {include_instit_info: true}}).then(session => {
          if (session.length > 0) {
            /* This is supposed to be assigned to a variable called test_session_group_id*/
            this.instit_group_id = session[0].testSession.test_session_group_id; 
            
            this.format = session[0].testSession.room;
            this.institution = session[0].institution.name;     
            this.confirmReportData["format"] = this.format;
            this.confirmReportData["invigilator"] =this.institution;    
          }

          
          
          Object.keys(testDesign.questionDb).forEach(questionId => {
            try {
              const question = testDesign.questionDb[questionId];
              question.test_question_version_id = questionId;
              this.questionSrcDb.set(+questionId, question);
            } catch (err) {
              console.warn('could not process question id', questionId, err);
            }
          });

          this.currentWarning = null;
          this.isLoaded = true;
          this.isLoadingAttempt = false;             
        });
      })
      .catch(e => {
        this.isLoadingAttempt = false;
        this.currentWarning = this.parseAttemptLoadErrorMessage(e.message);
      });
  }

  durIsZero(durObj: IDur) {
    return durObj.hours === 0 && durObj.minutes === 0
  }
  updateTimeRemaining = () => {
    const totalTime = this.test_window_time + this.time_ext_m;
    const leadingZero = (num: number) => {
      let str = '' + Math.floor(num);
      if (str.length === 1) {
        return '0' + str;
      }
      return str;
    };
  

   const getDurObj = (timeSinceStart_m: number, totalTime= 0) => {
      const timeRemaining_m = Math.abs(totalTime - timeSinceStart_m);
      const timeRemaining_h = Math.floor(timeRemaining_m / 60);
      const timeRemaining_m_r = Math.floor(timeRemaining_m - timeRemaining_h * 60);
      return {hours: timeRemaining_h, minutes: timeRemaining_m_r};
    };
  
     
      const timeSinceStart_ms =  - this.dateTimeStart.diff(moment());
      const timeSinceStart_m = (timeSinceStart_ms / 1000) / 60;

      this.isNotYetOpen = (timeSinceStart_ms < 0);
      if (this.isNotYetOpen) {
        this.timeTillOpen = getDurObj(-timeSinceStart_m);
      }
      if (timeSinceStart_m >= totalTime) {
        this.regularTimeRemaining = {hours: 0, minutes: 0};
      } else {
        this.regularTimeRemaining = getDurObj(timeSinceStart_m, totalTime);
      }    
      this.timeSpentSinceStart= timeSinceStart_m;
      this.confirmReportData["timeSpent"] = this.timeSpentSinceStart;
  }

  public saveQuestionResponse = (data: any) => {
    return this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_QUESTION_RESPONSE,
      {
        ... data,
        test_attempt_id:  this.testAttemptInfo.attemptId,
      }
    );
  }

  public checkTime = () => {
    const test_session_id: number = parseInt('' + this.sessionId);
    const sebFailSafe: number = this.seb.getSEBFaileSafeCheck();
    const validatedSEBConfig:number = this.auth.getSEBValidatorStatus();
    return this.auth.apiFind(
      this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_TIME,
      { query: {test_session_id, sebFailSafe, validatedSEBConfig} }
    )
    . then( time => {
      this.time_ext_m = time[0].time_ext_m;
    });
  }

  public checkChat = () => {
    return Promise.resolve();
  }

  public submitTest = () => {
    return this.auth.apiPatch(
      this.routes.TEST_TAKER_INVIGILATION_QUESTION_RESPONSE, // bad name ... to do
      1,
      { test_attempt_id:  <any> this.testAttemptInfo.attemptId }
    )
    .then( r => {
      this.mptConnection.updateCompleted(this.auth.user().value.uid, true);
      /*
      this.auth
      .apiGet(this.routes.TEST_TAKER_REPORT_RESULTS, this.confirmReportData["attemptId"], {query: {getreport: true}})
      .then((report: IReport) => {
        this.report = report;
        //console.log(this.report)
      } )
      .catch(e => {
         this.reportError = true;
         this.reportErrorMsg = this.parseLoadError(e.message);
         //console.log(this.reportErrorMsg)
      });
      */
      this.my.gotoDashboard();
    });
  }

  parseLoadError(msg) {
    switch (msg) {
      case 'WRONG_USER': return this.lang.tra('txt_error_login');
      case 'NOT_YET_RELEASED': return this.lang.tra('txt_res_unavail');
      default: return 'An error occured while loading your results';
    }
  }

  public goBack() {
    this.router.navigate([
      this.lang.c(),
      AccountType.TEST_TAKER,
      'dashboard'
    ]);
  }


  isInSelectionStep(currentWarning:WarningModes){
    switch(currentWarning){
      case WarningModes.SELECT_LANG:
      case WarningModes.ACCEPT_ATTEST:
        return true;
      
      default: return false;
    }
  }

  private parseAttemptLoadErrorMessage(message: string) {
    switch (message) {

      case WarningModes.INVALID_SEB:
      case 'SEB header used but no matching available SEB headers found for this institution':
      case 'SEB header hash matched but is revoked':
      case 'SEB header does not match expected hash':
      case 'Missing SEB header':
        this.unknownWarningErrorMsg = message;
        return WarningModes.INVALID_SEB;

      case ApiFailCode.GROUP_ROLE_REQ_FOR_ROUTE:
        return WarningModes.NOT_BOOKED_APPL;

      // to do: ideally every 
      case WarningModes.NO_QUESTIONS_FOUND:
      case WarningModes.SELECT_LANG:
      case WarningModes.ACCEPT_ATTEST:
        return <WarningModes> message;

      case 'Missing Institution ID':
      default :
        this.unknownWarningErrorMsg = message;
        return WarningModes.UNKNOWN;
    }
  }

  logout() {
    this.auth.logout() .then( r => {
      window.location.reload();
    });
  }

}
